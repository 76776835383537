<template>
  <div>
    <balances-stats></balances-stats>
    <div class="flex flex-wrap mt-4">
      <div class="w-full">
        <card-user />
      </div>
      <!-- <div class="w-full mb-12 px-4">
        <card-transaction color="dark" />
      </div> -->
    </div>
  </div>
  
</template>
<script>
import CardUser from "@/components/Cards/CardUser.vue";
import BalancesStats from "@/components/Stats/BalancesStats.vue";

export default {
  components: {
    CardUser,
    BalancesStats
  },
};
</script>
