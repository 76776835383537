<template>
    <div>
        <div v-if="visible" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
            <div class="relative my-6 mx-auto" style="min-width: 30%;">
                <!--content-->
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-lg">
                    Transaction Pin
                    </h3>
                    <button 
                    class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                    v-on:click="closeModal"
                    >
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                    </button>
                </div>
                
                <form class="mt-4 px-4">
                    <div class="w-full px-4">
                        <div class="relative w-full mb-3">
                            <label
                                class="block text-blueGray-600 text-xs capitalize font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Enter transaction pin
                            </label>
                            <input 
                                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                                id="transaction-pin" 
                                v-model="v$.pin.$model"
                                placeholder="0000"
                                type="password"
                            />
                        </div>
                    </div>
                    
                    <div class="relative w-full mb-3 px-4 mt-2">
                        <button 
                            class="bg-red-500 text-white active:bg-red-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" 
                            v-on:click="closeModal"
                        >
                            Cancel
                        </button>
                        <app-button 
                            :classes="'bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150'" 
                            @click="verifyPin" 
                            :title="'Confirm'" 
                            :loading="loaders().confirmPin" 
                            :disabled="loaders().confirmPin || v$.pin.$invalid" 
                        >
                        <i class="pi pi-filter text-sm" style="font-size: 10px;"></i>    
                        </app-button>
                    </div>
                </form>

                <div class="flex items-center justify-end border-t border-solid border-blueGray-200 rounded-b px-4">
                    <button 
                        class="text-red-500 background-transparent px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                        v-on:click="closeModal"
                    >
                        Close
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div v-if="visible" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
    </div>
</template>

<script>
import AppButton from '@/components/Form/AppButton.vue';
import { mapActions, mapGetters } from 'vuex';
import { required, minLength, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
    name: 'TransactionPinModal',
    components: {
        AppButton
    },
    props: {
        currency: {
            type: String,
            default: 'NGN',
            required: false
        },
        visible: {
            type: Boolean,
            required: true
        },
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
        pin: { required, min: minLength(4), max: maxLength(4) },
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        ...mapActions([
            "confirmPin",
        ]),
        ...mapGetters([
            "loaders"
        ]),
        verifyPin() {
            this.confirmPin({pin: this.pin, currency: this.currency}).then(() => {
                toaster.success("Transaction pin validated!");
                const base64Pin = btoa(this.pin);
                window.localStorage.setItem("tp", base64Pin)
                const self = this;
                setTimeout(() => {
                self.$emit("verified", true)
                }, 700)
                setTimeout(() => {
                window.localStorage.removeItem("tp")
                }, 3000);
            }).catch( e => {
                const { message = "Ops, something went wrong!" } = e;
                toaster.error(message)
                this.$emit("verified", false)
            })
        }
    },
    data() {
        return {
            pin: ""
        }
    }
}
</script>