<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Question</h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="question={}"
          v-if="has('questions.store')"
        >
          Question
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0" v-if="has('questions.show')">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Question Information
        </h6>

        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Type
              </label>
              <select v-model="v$.question.type.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in ["ONBOARDING", "PROFILE", "LOAN", "SAVINGS", "INVESTMENT"]' :key="i" :selected="cur === question.type">{{ cur }}</option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.question.type.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Point
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.question.point.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.question.point.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>


        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
         Details
        </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Link (video or image)
            </label>
            <input
              type="text"
              id="risk-type"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              v-model="v$.question.link.$model"
            />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Question
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                rows="4"
                v-model="v$.question.question.$model"
              ></textarea
              >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.question.question.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                rows="4"
                v-model="v$.question.description.$model"
              ></textarea
              >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.question.description.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative w-full mb-12 px-4 mt-2">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="processQuestion" 
            :disabled="loaders().updatePlan"
            v-if="has('questions.update') || has('questions.store')"
          >
            Save
          </button>
        </div>
        <hr/>

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Answers
        </h6>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3" v-if="has('answers.show')">
            <ul>
              <li v-for="(answer, i) in question.answers" :key="i" class="w-full flex justify-between">
                <span>{{ answer.answer }} <small>({{ answer.insight }}) - {{ answer.point  }}</small>  </span>
                <small class="">
                  <button 
                    v-if="has('answers.destroy')"
                    class="bg-red-500 text-white active:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                    @click="_removeAnswer(answer)"
                  >
                    <!-- detach -->
                    <i class="fa fa-times"></i>
                  </button>

                  <button 
                    v-if="has('answers.update')"
                    class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                    @click="(e) => {e.preventDefault(); handleEditAnswer(answer)}"
                  >
                    <!-- detach -->
                    <i class="fa fa-pen"></i>
                  </button>

                </small>
              </li>
            </ul>
          </div>
          <permission-err v-else></permission-err>
          
        </div>

        <!-- {{ coinType() }} -->
        <div class="flex flex-wrap" v-if="has('answers.store')">
          <div class="w-full lg:w-7/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Answer
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.answer.answer.$model"
              />

              <div class="input-errors text-xs text-danger mt-2" >
                <div class="error-msg"> </div>
              </div>

            </div>
          </div>
        </div>

        <div class="flex flex-wrap" v-if="has('answers.store')">

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Insight
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.answer.insight.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" >
                <div class="error-msg"> </div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Point
              </label>
              <input
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.answer.point.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" >
                <div class="error-msg"> </div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                .
              </label>
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="saveAnswer" 
                :disabled="loaders().attachAnswer"
                v-if="has('answers.store')"
              >
              <!-- || v$.coin_type.$invalid -->
                Add 
              </button>
            </div>
          </div>

        </div>

      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, numeric, minLength, alphaNum } from '@vuelidate/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });
import PermissionErr from "@/components/Cards/PermissionErr.vue";

export default {
  props: [ "selectedQuestion" ],
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    PermissionErr
  },
  watch: {
    selectedQuestion(oldQuestion) {
      this.question = oldQuestion;
    }
  },
  data() {
    return {
      question: this.selectedQuestion,
      answer: {
        point: 0
      }
    }
  },
  mounted() {
    
  },
  validations() {
    return {
      question: {
        question: { required, alphaNum },
        description: { required },
        type: { required },
        is_active: { required, Boolean },
        point: { required, numeric, min: minLength(1) },
        link: {}
      },
      answer: {
        answer: { required },
        insight: { required },
        point: { required, numeric, min: minLength(1) }
      }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([
        "addQuestion",
        "editQuestion",
        "deleteQuestion",
        "addAnswer",
        "editAnswer",
        "deleteAnswer",
      ]),
    ...mapMutations(["removeQuestion", "removeAnswer", "updateQuestion"]),
    handleEditAnswer(ans){
      this.answer = ans
    },
    saveAnswer() {
      const data = this.answer;
      if ( !this.question.id ) {
        toaster.show('please select/save a question first');
        return false;
      }
      data.question_id = this.question.id;

      const query = data.id ? this.editAnswer(data) : this.addAnswer(data);

      query.then( res => {
        console.log(res);
        this.answer = {}
      });
    },
    _removeAnswer(answer) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
        return;
      }

      if ( !this.question.id ) {
        toaster.show('please select a question first');
        return false;
      }

      this.deleteAnswer(answer.id).then( res => {
        console.log(res)
        // this.removeAnswer(answer)
        this.question.answers = this.question.answers.filter( a => a.id !== answer.id)
      });
    },
    processQuestion() {
      const data = this.question;
      
      if ( this.question.id  ) {
        const self = this;
        data.point = ""+data.point
        this.editQuestion(data).then(res => {
          console.log(res)
          self.updateQuestion(data)
        });
      } else {
        this.addQuestion(data)
      }
      
    },
    removeQuestion() {
      const data = this.question;
      const self = this;
      this.deleteQuestion(data.id).then( () => {
        self.removeQuestion(data)
      });
    }
  }
}
</script>