export const ACTION_TYPE = {
    Add: "Add",
    Edit: "Edit"
}

export const ENVIRONMENT = process.env.NODE_ENV
export const IS_PROD = ENVIRONMENT === "production" 
export const BASE_URL = IS_PROD ? "https://api.redge.ng/" : "http://jawo.local/";
// export const BASE_URL = IS_PROD ? "https://api.redge.ng/" : "http://localhost:8080/";
export const ASTRO_PAY_APP_ID = "PbXuYJFEKLNMleTXz1jRkADgYFisgCMnr7k8LSsJVA7Am9giVkixBNHxoo16azxA"
export const PUSHER_APP_KEY = "90861b8f263129ad9082"
export const PUSHER_APP_CLUSTER = 'eu'
export const WS_HOST = IS_PROD ? 'socket.redge.ng' : 'localhost';
export const WS_PORT = IS_PROD ? "" : 6001
    // export const WS_HOST = 'api.redge.ng';
    // export const WS_PORT = 6001

export const transactionList = [
    "",
    "plan-subscription", 
    "alert-subscription", 
    "loan-subscription",
    "loan-request-fee",
    "loans-payment",
    "wallet-transfer",
    "savings-payment",
    "airtime-topup",
    "data-topup",
    "cable-tv",
    "power",
    "refund", 
    "funding",  
    "start-funding",
    "withdraw",
    "commission",
    "address-verification",
    "stamp-duty",
    "data-subscription",
]

export const commissionList = [
    "",
    "savings",
    "loan"
]

export const campaignList = [
    "push",
    "email",
    "sms"
]

export const recepients = [
    "all",
    "investors",
    "savers",
    "borrowers",
    "debtors"
]

export const providerList = [
    "",
    "vpay",
    "monify",
    "flutterwave",
    "korapay",
    "providus",
    "paycenter",
    "kuda",
    "paystack",
    "verifyme",
    "prembly"
]

export const logList = [
    "",
    "liveliness_verification",
    "bvn_verification",
    "nin_verification",
    "address_verification",
    "transfer_recipient",
    "direct_debit"
]

export const statusList = [
    '', 
    'INITIATED',
    'PENDING', 
    'FAILED', 
    'ACTIVE', 
    'DECLINED', 
    'RETRY', 
    'COMPLETED', 
    'WITHDRAWN', 
    'APPROVED', 
    'DUE', 
    'REVERSED', 
    'DEFAULT'
]