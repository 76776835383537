import { AddSubscription, GetSubscription, UpdateSubscription } from "@/services/subscription";

export const getSubscription = (context) => {
  context.commit("updateLoader", { fetchSubscription: true });
  return new Promise((resolve, reject) => {
    GetSubscription()
      .then(res => {
        context.commit("fetchSubscription", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { fetchSubscription: false });
      });
    });
};

export const addSubscription = (context, data) => {
  context.commit("updateLoader", { addSubscription: true });
  return new Promise((resolve, reject) => {
    AddSubscription(data)
      .then(res => {
        context.commit("addSubscription", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { addSubscription: false });
      });
    });
};

export const updateSubscription = (context, data) => {
  context.commit("updateLoader", { updateSubscription: true });
  return new Promise((resolve, reject) => {
    UpdateSubscription(data)
      .then(res => {
        context.commit("updateSubscription", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { updateSubscription: false });
      });
    });
};

const SubscriptionAction = {
  getSubscription,
  addSubscription,
  updateSubscription
};

export default SubscriptionAction;
