<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700">
            <i class="pi pi-building-columns text-sm"></i> Available Businesses
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <div class="flex flex-wrap">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead class="thead-light">
            <tr>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Business
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Admin Email
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Code
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Status
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                No. of Customers
              </th>
              <th
                class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="has('businesses.view.all')">
            <tr 
              class="cursor-pointer"
              v-for="(business, i) in businesses().data" :key="i"
            >
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                {{ business.name }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                @click="clickToCopy(business.support_email)"
              >
                {{ business.support_email || "--" }} <i v-if="business.support_email" class="pi pi-clone text-xs" ></i>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left cursor-pointer" 
                @click="clickToCopy(business.code)"
              >
                {{ business.code || 'N/A' }} <i v-if="business.code" class="pi pi-clone text-xs" ></i>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left" 
                :class="{
                  'text-emerald-500': business?.status?.toLowerCase() === 'approved',
                  'text-red-500': business?.status?.toLowerCase() === 'blocked',
                  'text-red-500': business?.status?.toLowerCase() === 'partially_blocked',
                  'text-blueGray-400': business?.status?.toLowerCase() === 'pending'
                }"
              >
                {{ business.status }} 
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                {{ business.customers_count }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <button 
                  class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                  @click="handleShowBusines(business)"
                  v-if="has('business.show')"
                ><i class="pi pi-eye text-xs"></i>
                </button>
                <button 
                  class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                  @click="$emit('activeBusinessSelected', business)"
                  v-if="(has('business.update') || has('business.confirm.update')) && has('business.show')"
                ><i class="pi pi-file-edit text-xs"></i>
                </button>
                <button 
                  class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                  @click="handleActAsBusiness(business)"
                  v-if="has('business.show') || has('business.view')"
                >
                  <!-- detach -->
                  <i class="fas fa-hand-pointer text-xs"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <permission-err title="businesses" v-if="!has('businesses.view.all')"></permission-err>
      </div>
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <circular-pagination :current_page="businesses().current_page" :last_page="businesses().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
        </div>
      </div>
    </div>

    <div v-if="modals.business" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Business Details
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('business', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <recursive-table
              :data="business"
              :excludeAll="['id', 'user_id', 'business_id']"
              :imagesAll="['logo', 'banner', 'image']"
              :telsAll="['phone']"
              :emailsAll="['email', 'support_email']"
              :datesAll="['created_at', 'updated_at']"
            />

            <button 
              class="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="isMapVisible=!isMapVisible"
            >
              <span v-if="isMapVisible">Hide Map</span><span v-else>Show Map</span>
            </button>

            <map-pan :longitude="business.user?.profile?.longitude" :latitude="business.user?.profile?.latitude" v-if="isMapVisible" ></map-pan>
            <div class="m-4 text-sm" v-else>
                Live Location:  {{ business.user?.profile?.longitude }}, {{ business.user?.profile?.latitude }}
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">

            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('business', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.business" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import { clickToCopy } from "@/utils";
import MapPan from "@/components/Maps/MapPan.vue";
import RecursiveTable from "@/components/Tables/RecursiveTable.vue";
import PermissionErr from "@/components/Cards/PermissionErr.vue";

export default {
  props: [],
  components: {
    CircularPagination,
    RecursiveTable,
    MapPan,
    PermissionErr
  },
  methods: {
    ...mapActions(["getBusinesses"]),
    ...mapGetters([
      "loaders",
      "businesses",
    ]),
    ...mapMutations(["actingAsBusiness"]),
    handleActAsBusiness(business) {
      this.actingAsBusiness(business)
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewUserPage();
    },
    fetchNewUserPage() {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv
      }
      if ( !params.get_csv ) {
        // this.updateTransactionQueryParams(params)
        this.$emit('businessChange', params)
      }
      const self = this
      this.loading = true
      console.log(params)
      this.getBusinesses(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','transactions.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          // toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    handleShowBusines(b){
      this.business = b; 
      this.toggleModal('business', true);
    }
  },
  data() {
    return {
      business: {},
      get_csv: false,
      pagination: {},
      pageSize: 15,
      clickToCopy: null,
      modals: {},
      isMapVisible: false
    }
  },
  mounted() {
    this.clickToCopy = clickToCopy
  }
}
</script>
