<template>
  <div class="flex flex-wrap mt-5">
    <div class="w-full lg:w-8/12 px-2">
      <card-rate-list :currencies="currencies()" @selectedRate="changeRate" />
    </div>
    <div class="w-full lg:w-4/12 px-2">
      <CardRates :selectedRate="rate" />
    </div>
  </div>
</template>
<script>
import CardRates from "@/components/Cards/CardRates.vue";
// import CardProfile from "@/components/Cards/CardProfile.vue";
import CardRateList from "@/components/Cards/CardRateList.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CardRates,
    CardRateList,
  },
  data() {
    return {
      rate: {}
    }
  },
  mounted() {
    this.getAllCurrencies();
  },
  methods: {
    ...mapActions(["getAllCurrencies"]),
    ...mapGetters(["currencies"]),
    changeRate(rate) {
      this.rate = rate
    }
  }
};
</script>
