<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <div class="text-center flex justify-between py-3">
        <h6 class="text-blueGray-400 text-sm">Roles</h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button" 
          @click="showRoleForm"
          v-if="has('roles.store') && !showForm"
        >
        <i class="pi pi-plus-circle text-sm" style="font-size: 10px;"></i> Add Role
        </button>
        <button
          class="bg-red-500 text-white active:bg-emerald-600 text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button" 
          @click="showForm=false"
          v-if="has('roles.store') && showForm"
        >
        <i class="pi pi-times-circle text-sm" style="font-size: 10px;"></i> Cancel
        </button>
      </div>

      <hr class="my-1"/>
      <div class="w-full lg:w-12/12 mt-2" v-if="!showForm">
        <div class="relative w-full mb-3">
          <ul>
            <li v-for="(role, i) in roles" :key="i" @click="selectRole(role)" class="w-full cursor-pointer flex justify-between my-2">
              <div><i class="pi pi-key text-sm" style="font-size: 10px;"></i> <span>{{ role.name }} </span></div>
              <div>
                <small 
                  @click="handleEditRole(role)" 
                  class="cursor-pointer hover:text-white bg-emerald-500 text-blueGray-100 hover:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  v-if="has('roles.store')"
                >
                  <i class="pi pi-file-edit"></i>
                </small>
                <small 
                  @click="removeRole(role)" 
                  class="cursor-pointer hover:text-white bg-red-500 text-blueGray-100 hover:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  v-if="has('roles.destroy')"
                >
                  <i class="pi pi-times-circle"></i>
                </small>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex-auto px-4 lg:px-4 pb-4 pt-0" v-if="(has('roles.update') || has('roles.store')) && showForm">
      <form>
        <h6 class="text-blueGray-400 text-sm mb-3">
          Role Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.role.name.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.role.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        
          <div class="w-full px-4 py-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
              Is Admin Role
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.role.is_admin.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                <span class="ml-2 text-xs text-emerald-600 mr-2"> Is Admin? </span>
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.role.is_admin.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-4 py-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
              Is Business Role
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.role.is_business.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                <span class="ml-2 text-xs text-emerald-600 mr-2"> Is Business? </span>
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.role.is_business.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

        </div>

        <div class="relative w-full mb-6 mt-4">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold text-xs py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="persistRole" 
            :disabled="loaders().updateRole || loaders().addRole"
            v-if="has('roles.update') || has('roles.store')"
          >
          <i class="pi pi-bookmark text-sm" style="font-size: 10px;"></i> Save
          </button>
        </div>
        
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import { confirmAction } from "@/utils";
const toaster = createToaster({ /* options */ });
import Swal from 'sweetalert2'

export default {
  props: [ "roles" ],
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      role: {},
      permission: {},
      showForm: false
    }
  },
  validations() {
    return {
      role: {
        name: { required },
        is_business: {},
        is_admin: {}
      },
      permission: {
        name: {}
      }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([
      "deleteRole", 
      "updateRole", 
      "addRole",
    ]),
    showRoleForm(){
      this.role = {}; 
      this.showForm = true
    },
    handleEditRole(role) {
      this.selectRole(role); 
      this.showForm = true
    },
    selectRole(role) {
      if (!this.has('role.permission.view')) {
        toaster.warning("You don't have the permission to view this role")
        Swal.fire({
          title: "Missing permissions!",
          text: `You don't have the required permission to view ${role.name} role`,
          icon: "warning",
          cancelButtonColor: "#d33",
        });
        return
      }
      this.role = role;
      this.role.is_admin = !!role.is_admin;
      this.role.is_business = !!role.is_business;

      this.$emit('selectedRole', role)
    },
    persistRole() {
      const data = this.role;
      data.id ? this.updateRole(data) : this.addRole(data).then( () => this.role = {})
    },
    removeRole(role) {
      confirmAction({
        confirmText: `Delete ${role.name}`,
        confirmTextHelper: `Kindly confirm you want to delete ${role.name} role`,
        successText: `You have successfully deleted ${role.name} role`,
        cancelText: `Deletion of role aborted`
      }, () => {
        this.deleteRole(role.id);
      });
    }
  }
}
</script>