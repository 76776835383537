<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0" v-if="has('commissions.view')">
            <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              <i class="pi pi-database"></i> Commission
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0" v-if="has('fees.index')">
            <product-package package="standard">
              <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
                <i class="pi pi-cog"></i> Setup
              </a>
            </product-package>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div class="flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div class="w-full">
                <card-commission />
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <transition name="slide-fade">
                <div class="w-full" :class="{'lg:w-8/12': fee != null}">
                  <card-fee-list :fees="fees()" @create="handleCreate" />
                </div>
              </transition>
              <transition name="slide-fade">
                <div class="w-full lg:w-4/12 px-4" v-if="fee != null">
                  <card-fee :selectedFee="fee" @close="handleFeeClose" />
                </div>
              </transition>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardCommission from "@/components/Cards/CardCommission.vue";
import CardFeeList from "@/components/Cards/CardFeeList.vue";
import CardFee from "@/components/Cards/CardFee.vue";
import { mapActions, mapGetters } from 'vuex';
import ProductPackage from "@/components/Cards/ProductPackage.vue";

export default {
  components: {
    CardCommission,
    CardFeeList,
    CardFee,
    ProductPackage
  },
  data() {
    return {
      fee: null,
      openTab: 1,
    }
  },
  mounted() {
    this.fetchFees()
    this.getRoles()
  },
  methods: {
    ...mapActions(["getRoles", "fetchFees"]),
    ...mapGetters(["fees"]),
    handleFeeClose() {
      this.fee = null
    },
    handleCreate(fee = {}) {
      this.fee = fee
    },
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
  }
};
</script>
