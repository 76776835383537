export const fetchSubscription = (state, subscription) => {
  state.subscription = subscription;
};

export const addSubscription = (state, {subscriptions, cost, data}) => {
  state.subscription = subscriptions;
  state.subscription_cost = cost
  state.subscription_payload = data
};

export const updateSubscription = (state, subscription) => {
  state.subscription = subscription
};

const SubscriptionMutation = {
  fetchSubscription,
  addSubscription,
  updateSubscription
};

export default SubscriptionMutation;
