<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
  >
    <div class="px-6">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 flex justify-center">
          <div class="relative">
            <!-- <img
              alt="..."
              :src="team2"
              class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
            /> -->
            <i class="pi pi-user"></i>
          </div>
        </div>
        <div class="w-full px-4 text-center mt-4">
          <!-- <div class="flex justify-center py-4 lg:pt-4 pt-8">
            <div class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
                22
              </span>
              <span class="text-sm text-blueGray-400">Friends</span>
            </div>
            <div class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
                10
              </span>
              <span class="text-sm text-blueGray-400">Photos</span>
            </div>
            <div class="lg:mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
                89
              </span>
              <span class="text-sm text-blueGray-400">Comments</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="mt-2">
        <h3
          class="text-xl leading-normal mb-2 text-blueGray-700"
        >
          {{ userProfile().first_name }} {{ userProfile().last_name }}
          <br/>
          <small class="text-xs">{{ auth().email }}</small>
        </h3>
        <div
          class="text-sm leading-normal mt-0 mb-2"
        >
          <i class="pi pi-map-marker"></i>
          {{ userProfile().city }} {{ userProfile().state }}, {{ userProfile().country }}
        </div>
        <div class="mb-2 text-blueGray-600 mt-4">
          <i class="pi pi-briefcase"></i>
          Business - 
          {{ businesses().data[0] ? businesses().data[0].code || "Pending" : "--" }}
        </div>
        <div class="mb-2 text-blueGray-600">
          <i class="fas fa-university mr-2 text-lg"></i>
          {{ businesses().data[0] ? businesses().data[0].name : "--" }}
        </div>
      </div>
      <div class="mt-4 py-10 border-t border-blueGray-200 text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-9/12 px-4">
            <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
              {{ userProfile().about }}
            </p>
            <!-- <a href="javascript:void(0);" class="font-normal text-emerald-500">
              Show more
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import team2 from "@/assets/img/team-2-800x800.jpg";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // team2,
    };
  },
  methods: {
    ...mapGetters(["userProfile", "auth", "businesses"]),
  }
};
</script>
