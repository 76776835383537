import AccountState from "./account.state";
import LoaderState from "./loader.state";
import NotificationState from "./notification";
import TransactionState from "./transaction.state";
import UserState from "./user.state";
import QuestionState from "./question";
import PlanState from "./plan";
import ConfigState from "./config.state";
import SettingsState from "./settings.state";
import BankAccountState from "./bankAccount.state";
import ToastState from "./toast.state";
import MetricsState from "./metrics.state";
import CurrencyState from "./currency";
import RoleState from "./role";
import BusinessState from "./business.state";
import CampaignState from "./campaign.state"
import FeeState from "./fee";
import AppState from "./app.state";
import VasState from "./vas.state";
import SubscriptionState from "./subscription.state";

const states = {
    ...SettingsState,
    ...TransactionState,
    ...UserState,
    ...PlanState,
    ...LoaderState,
    ...NotificationState,
    ...AccountState,
    ...QuestionState,
    ...ConfigState,
    ...BankAccountState,
    ...ToastState,
    ...MetricsState,
    ...CurrencyState,
    ...RoleState,
    ...BusinessState,
    ...CampaignState,
    ...FeeState,
    ...AppState,
    ...VasState,
    ...SubscriptionState
};

export default states;