export const loadTransactions = (state, transactions) => {
  state.transactions = transactions;
};

export const getPaymentLogs = (state, paymentLogs) => {
  state.paymentLogs = paymentLogs
}

export const getProviderLogs = (state, providerLogs) => {
  state.providerLogs = providerLogs
}

export const updateTransactionStatus = (state, payload) => {
  const { transactionId, transaction } = payload;
  const transactionIndex = state.transactions.data.findIndex(
    (_transaction) => _transaction.id === transactionId
  );
  if (transactionIndex < 0) {
      return;
  }
  state.transactions.data[transactionIndex] = {...state.transactions.data[transactionIndex], status: transaction.status};
  state.transactions.data = [transaction, ...state.transactions.data];
}

export const updateTransactionQueryParams = (state, transactionQueryParams) => {
  state.transactionQueryParams = transactionQueryParams;
};

export const withdrawToAdmin = (state, payload) => {
  const userIdx = state.users.data.findIndex(
    (user) => user.id === payload.user_id
  );

  if (userIdx < 0) {
      return;
  }
  const currency = payload.currency.toLowerCase() === "ngn" ? "naira" : payload.currency.toLowerCase()
  const user = state.users.data[userIdx];
  user[`${currency}_wallet`]['ledger_balance'] -= payload.amount;
  user[`${currency}_wallet`]['balance'] -= payload.amount;

  state.users.data[userIdx] = user;
}

const TransactionMutation = {
  loadTransactions,
  updateTransactionQueryParams,
  updateTransactionStatus,
  getPaymentLogs,
  getProviderLogs,
  withdrawToAdmin
};

export default TransactionMutation;
