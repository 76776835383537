<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <h6 class="text-blueGray-400 text-sm mt-3 mb-3">
        Roles
      </h6>
      <hr class="my-2"/>
      <div class="w-full lg:w-12/12">
        <div class="relative w-full mb-3">
          <ul>
            <li 
              v-for="(role, i) in roles" 
              :key="i" @click="selectRole(role)" 
              class="w-full cursor-pointer"
            >
              <i class="pi pi-key text-sm" style="font-size: 10px;"></i> <span>{{ role.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: [ "roles" ],
  data() {
    return {
      role: {},
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([
    ]),
    selectRole(role) {
      this.role = role;
      this.$emit('selectedRole', role)
    }
  }
}
</script>