import http from "./http";

export const VendAirtime = async data => {
    return await http.post("/vas/airtime", data);
};

export const FetchData = async ({network, params}) => {
    return await http.get(`/vas/data/${network}`, {params});
};

export const VendData = async data => {
    return await http.post("/vas/data", data);
};

export const FetchPower = async params => await http.get("/vas/power", {params});

export const ValidatePower = async data => {
    return await http.post("/vas/power/validate", data);
};

export const VendPower = async data => {
    return await http.post("/vas/power", data);
};

export const FetchCable = async ({bouquet, params}) => {
    return await http.get(`/vas/cable/${bouquet}`, {params});
};

export const ValidateCable = async data => {
    return await http.post("/vas/cable/validate", data);
};

export const VendCable = async data => {
    return await http.post("/vas/cable", data);
};