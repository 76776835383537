import { 
    GetUserBusinesses, ConfirmBusiness, AddBusiness, 
    GetBusinesses, UpdateBusiness, DeleteBusiness,
    GetCommission, PayCommission, PayBulkCommission, UpdateCommission, DeleteCommission,
    AddBusinessManager, LeaveBusiness
} from "@/services/business";


export const getBusinesses = (context, params) => {
    context.commit("updateLoader", { getBusinesses: true });
    return new Promise((resolve, reject) => {
        GetBusinesses(params)
            .then(res => {
                context.commit("getBusinesses", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getBusinesses: false });
            });
    });
};

export const confirmBusiness = (context, data) => {
    context.commit("updateLoader", { confirmBusiness: true });
    return new Promise((resolve, reject) => {
        ConfirmBusiness(data)
            .then(res => {
                // context.commit("confirmBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmBusiness: false });
            });
    });
};

export const getUserBusinesses = (context) => {
    context.commit("updateLoader", { getUserBusinesses: true });
    return new Promise((resolve, reject) => {
        GetUserBusinesses()
            .then(res => {
                context.commit("getUserBusinesses", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getUserBusinesses: false });
            });
    });
};

export const addBusiness = (context, data) => {
    context.commit("updateLoader", { addBusiness: true });
    return new Promise((resolve, reject) => {
        AddBusiness(data)
            .then(res => {
                context.commit("addBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addBusiness: false });
            });
    });
};

export const updateBusiness = (context, data) => {
    context.commit("updateLoader", { updateBusiness: true });
    const id = data.id;
    delete data.id
    return new Promise((resolve, reject) => {
        UpdateBusiness(id, data)
            .then(res => {
                context.commit("updateBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateBusiness: false });
            });
    });
};

export const deleteBusiness = (context, data) => {
    context.commit("updateLoader", { deleteBusiness: true });
    return new Promise((resolve, reject) => {
        DeleteBusiness(data)
            .then(res => {
                context.commit("deleteBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteBusiness: false });
            });
    });
};

export const getCommission = (context, data) => {
    context.commit("updateLoader", { getCommission: true });
    return new Promise((resolve, reject) => {
        GetCommission(data)
            .then(res => {
                context.commit("getCommission", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCommission: false });
            });
    });
};

export const payCommission = (context, id) => {
    context.commit("updateLoader", { payCommission: true });
    return new Promise((resolve, reject) => {
        PayCommission(id)
            .then(res => {
                context.commit("payCommission", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { payCommission: false });
            });
    });
};

export const payBulkCommission = (context, data) => {
    context.commit("updateLoader", { payBulkCommission: true });
    const id = data.id;
    delete data.id
    return new Promise((resolve, reject) => {
        PayBulkCommission(id, data)
            .then(res => {
                context.commit("payBulkCommission", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { payBulkCommission: false });
            });
    });
};

export const updateCommission = (context, data) => {
    context.commit("updateLoader", { updateCommission: true });
    const id = data.id;
    delete data.id
    return new Promise((resolve, reject) => {
        UpdateCommission(id, data)
            .then(res => {
                context.commit("updateCommission", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateCommission: false });
            });
    });
};

export const deleteCommission = (context, data) => {
    context.commit("updateLoader", { deleteCommission: true });
    return new Promise((resolve, reject) => {
        DeleteCommission(data)
            .then(res => {
                context.commit("deleteCommission", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteCommission: false });
            });
    });
};

export const addBusinessManager = (context, data) => {
    context.commit("updateLoader", { addBusinessManager: true });
    const id = data.business_id;
    delete data.business_id
    return new Promise((resolve, reject) => {
        AddBusinessManager(id, data)
            .then(res => {
                // context.commit("addBusinessManager", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addBusinessManager: false });
            });
    });
};

export const leaveBusiness = (context, data) => {
    const params = {
        code: data.code, 
        user_id: data.userPlan.user?.id
    };
    context.commit("leaveBusiness", {code: data.code, userPlan_id: data.userPlan.id});
    context.commit("updateLoader", { leaveBusiness: true });
    return new Promise((resolve, reject) => {
        LeaveBusiness(params)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { leaveBusiness: false });
            });
    });
};

const BusinessAction = {
    getBusinesses,
    confirmBusiness,
    getUserBusinesses,
    addBusiness,
    updateBusiness,
    deleteBusiness,
    getCommission,
    payCommission,
    payBulkCommission,
    updateCommission,
    deleteCommission,
    addBusinessManager,
    leaveBusiness
};

export default BusinessAction;