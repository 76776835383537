<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold"><i class="pi pi-sync"></i> Subscription</h6>
        <div class="flex mb-3">
          <button
            v-for="(frequency, i) of frequencies"
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full capitalize"
            type="button"
            @click="subscription.frequency=frequency"
            :class="{
              'bg-indigo-500': subscription.frequency===frequency,
              'active:bg-indigo-600': subscription.frequency===frequency,
              'bg-emerald-500': subscription.frequency!==frequency,
              'active:bg-emerald-600': subscription.frequency!==frequency
            }"
            :key="i"
          >
            <i class="pi pi-briefcase text-xs" style="font-size: 10px;"></i> {{ frequency }}
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap w-full">
      <div class="w-full p-4 bg-white mt-4" v-if="businessSubscription && businessSubscription.length > 0" >
        <div class="flex flex-wrap w-full">  
          <div class="w-full lg:w-6/12">
            <p><strong>Active Plans</strong></p>
            <ul>
              <li v-for="(subscription, i) of businessSubscription" :key="i">
                <i class="pi pi-arrow-circle-right"></i> {{ subscription.package }} {{ subscription.product }} plan 
                <small>({{ subscription.duration }})</small>: 
                {{ formatter("NGN").format(subscription.amount) }}
                <small>-{{ formatter("NGN").format(subscription.discount_amount) }}</small>. 
                <i class="text-blueGray-400" v-if="moment(subscription.expires_at).isAfter(moment(new Date))">expires {{ subscription.expires_at }}</i>
                <i v-else class="text-red-500">expired</i>
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-6/12 justify-center items-center flex">
            <i class="text-3xl pi" :class="{
              'pi-verified': moment(businessSubscription[0]?.expires_at).isAfter(moment(new Date)),
              'pi-exclamation-circle': moment(businessSubscription[0]?.expires_at).isBefore(moment(new Date)),
              'text-emerald-600': moment(businessSubscription[0]?.expires_at).isAfter(moment(new Date)),
              'text-orange-500': moment(businessSubscription[0]?.expires_at).isBefore(moment(new Date)),
            }"
            v-if="businessSubscription && businessSubscription.length > 0"
            ></i>
          </div>
        </div>
        <div class="mt-2" v-if="moment(businessSubscription[0]?.expires_at).isBefore(moment(new Date))">
          <hr/>
          <button
            class="text-white text-xs px-4 py-2 mt-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full capitalize bg-indigo-500"
            type="button"
            @click="subscribe"
          >
            <i class="pi pi-briefcase text-xs" style="font-size: 10px;"></i> Renew now
          </button>
        </div>
      </div>
      <div v-else class="text-center w-full p-4 bg-white mt-4 text-blueGray">
        You currency do not have any active subscription. Kindly pick from one of the plans below to get started.
      </div>
    </div>
    <div class="flex flex-wrap w-full">
      <div class="w-full lg:w-4/12 px-4" v-for="([package_name, p], i) of Object.entries(packages)" :key="i">
        <div
          class="relative flex flex-col break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-4 mb-16 p-6"
        >
            <div
              class="text-lg leading-normal mb-2 font-bold text-emerald-500 capitalize mb-6"
            >
              <i class="pi" :class="p.icon"></i> {{ package_name }} Package 
            </div>
            <hr/>
            <div class="my-4 flex justify-end">
              <button
                v-for="(duration, j) of p.durations"
                class="flex text-white text-xs p-2 rounded shadow items-center hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full capitalize"
                type="button"
                @click="subscription.duration[package_name]=duration"
                :class="{
                  'bg-orange-500': subscription.duration[package_name]===duration,
                  'active:bg-orange-600': subscription.duration[package_name]===duration,
                  'bg-emerald-500': subscription.duration[package_name]!==duration,
                  'active:bg-emerald-600': subscription.duration[package_name]!==duration
                }"
                :key="j"
              >
                <i class="pi pi-calendar text-xs" style="font-size: 10px;"></i> {{ duration }}
              </button>
            </div>
            <div class="mb-2 cursor-pointer" :class="{
              'italic line-through text-blueGray-300': !benefit.permission.includes(subscription[package_name]),
              'text-blueGray-600': benefit.permission.includes(subscription[package_name])
            }" v-for="(benefit, j) in p.benefits" :key="j">
              <i class="pi pi-check-circle text-emerald-600" v-if="benefit.permission.includes(subscription[package_name])"></i>
              <i class="pi pi-times-circle text-red-500" v-else></i> {{ benefit.decription }}
            </div>
        </div>
        <div class="my-4 mr-4">
          <div 
            v-for="(price, j) in p.price_list"
            class="m-2 flex items-center p-4 rounded-lg border w-full border-emerald-500"
            :key="j"
          >
            <input 
              type="radio" 
              class="focus:border-emarald-500 active:border-emarald-500" 
              :name="package_name" 
              :value="price.name"
              v-model="v$.subscription[package_name].$model"
            />&nbsp;
            <span>
              {{ price.name }} <small><span class="text-orange-500">{{ price.comment }}</span> ({{ formatter("NGN").format(price.price[subscription.duration[package_name]]) }})</small>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="relative w-full p-4">
      <p><i class="pi pi-arrow-right"></i> Total: {{ formatter("NGN").format(total) }}</p>
      <p><i class="pi pi-arrow-right"></i> Discount: {{ formatter("NGN").format(total * 0.01 * tenant.discount) }} <small class="text-emerald-500">{{ tenant.discount }}%</small></p>
      <p class="font-bold mt-2"><i class="pi pi-arrow-right"></i> Grand Total: {{ formatter("NGN").format(total - (total * 0.01 * tenant.discount)) }}</p>
    </div>
    <div class="relative w-full p-4">
      <!-- <button
        class="w-full flex text-center justify-center text-lg p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        :class="{
          'bg-emerald-500 text-white active:bg-emerald-600': !v$.subscription.$invalid && (this.businessSubscription && this.businessSubscription.length > 0 && moment(businessSubscription[0]?.expires_at).isBefore(moment(new Date))),
          'bg-blueGray-100 text-blueGray-700 active:bg-blueGray-100': v$.subscription.$invalid || (this.businessSubscription && this.businessSubscription.length > 0 && moment(businessSubscription[0]?.expires_at).isAfter(moment(new Date)))
        }"
        :disabled="v$.subscription.$invalid || (this.businessSubscription && this.businessSubscription.length > 0 && moment(businessSubscription[0]?.expires_at).isAfter(moment(new Date)))"
        @click="subscribe"
      > -->
      <button
        class="w-full flex text-center justify-center text-lg p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 bg-emerald-500 text-white active:bg-emerald-600"
        type="button"
        :disabled="v$.subscription.$invalid"
        @click="subscribe"
      >
        <span v-if="false" class="flex loader loader-sm"></span>
        <span v-else><i class="pi pi-save"></i> Subscribe</span>
      </button>
    </div>
    
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { formatter, confirmAction } from '../../utils'
import { mapActions, mapGetters } from 'vuex';
import moment from "moment"
import PaystackPop from '@paystack/inline-js'

export default {
  data() {
    return {
      durations: {
        MONTH: "monthly",
        QUARTER: "quarterly",
        YEAR: "yearly"
      },
      frequencies: ["once", "reccuring"],
      subscription: {
        duration: {},
        frequency: null
      },
      packages: {
        savings: {
          title: "Savings",
          icon: "pi-bookmark",
          benefits: [
            {decription: "View and manage user savings", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Collect savings from users", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Up to 3000 savings transactions", permission: ["Basic", "Standard", "Premium"]},
            {decription: "You can create savings plan", permission: ["Standard", "Premium"]},
            {decription: "Configure your payment gateway", permission: ["Premium", "Standard"]},
            {decription: "Above 3000 savings transactions", permission: ["Standard", "Premium"]},
            {decription: "Specify your transaction cost", permission: ["Premium", "Standard"]},
            {decription: "You can create group savings plan", permission: ["Premium"]},
            {decription: "Get extra dashboard for staff/business leads", permission: ["Premium"]},
            {decription: "Set various roles and permission", permission: ["Premium"]}
          ],
          price_list: [
            {name: "Basic", comment: "free", price: {
              monthly: 0, quarterly: 0, yearly: 0
            }},
            {name: "Standard", comment: "", price: {
              monthly: 70000, quarterly: 230000, yearly: 660000
            }},
            {name: "Premium", comment: "", price: {
              monthly: 200000, quarterly: 720000, yearly: 2040000
            }},
          ],
          durations: ["monthly", "quarterly", "yearly"]
        },
        loan: {
          title: "Loans",
          icon: "pi-building-columns",
          benefits: [
            {decription: "View and manage user loans", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Accept loan request from users", permission: ["Basic", "Standard", "Premium"]},
            {decription: "You can confirm and disburse loans", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Set up loan request fee", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Up to 2000 loan transactions", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Create and configure loan plan", permission: ["Standard", "Premium"]},
            {decription: "Configure your payment gateway", permission: ["Premium", "Standard"]},
            {decription: "Above 2000 savings transactions", permission: ["Standard", "Premium"]},
            {decription: "Specify your transaction cost", permission: ["Premium", "Standard"]},
            {decription: "2 levels of confirmation", permission: ["Premium"]},
            {decription: "You can group loan plan per staff/business", permission: ["Premium"]},
            {decription: "Get extra dashboard for staff/business", permission: ["Premium"]},
            {decription: "Set various roles and permission", permission: ["Premium"]}
          ],
          price_list: [
            {name: "Basic", comment: "free", price: {
              monthly: 0, quarterly: 0, yearly: 0
            }},
            {name: "Standard", comment: "", price: {
              monthly: 200000, quarterly: 740000, yearly: 2100000
            }},
            {name: "Premium", comment: "", price: {
              monthly: 350000, quarterly: 1320000, yearly: 3840000
            }},
          ],
          durations: ["monthly", "quarterly", "yearly"]
        },
        investment: {
          title: "Investments",
          icon: "pi-wallet",
          benefits: [
            {decription: "View and manage user investments", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Collect investments from users", permission: ["Basic", "Standard", "Premium"]},
            {decription: "Up to 100 investments transactions", permission: ["Basic", "Standard", "Premium"]},
            {decription: "You can create investment plan", permission: ["Standard", "Premium"]},
            {decription: "Configure your payment gateway", permission: ["Premium", "Standard"]},
            {decription: "Above 100 investments transactions", permission: ["Standard", "Premium"]},
            {decription: "Specify your transaction cost", permission: ["Premium", "Standard"]},
            {decription: "You can create group investments plan", permission: ["Premium"]},
            {decription: "Get extra dashboard for staff/business leads", permission: ["Premium"]},
            {decription: "Set various roles and permission", permission: ["Premium"]}
          ],
          price_list: [
            {name: "Basic", comment: "free", price: {
              monthly: 0, quarterly: 0, yearly: 0
            }},
            {name: "Standard", comment: "", price: {
              monthly: 70000, quarterly: 230000, yearly: 660000
            }},
            {name: "Premium", comment: "", price: {
              monthly: 250000, quarterly: 940000, yearly: 2700000
            }},
          ],
          durations: ["monthly", "quarterly", "yearly"]
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      businessSubscription: "subscription",
      tenant: "tenant"
    }),
    total() {
      let total = 0;
      if (this.subscription && this.subscription.duration) {
        for (const pack in this.subscription.duration) {
          const package_name = this.subscription[pack]
          const duration = this.subscription.duration[pack]
          const price_package = this.packages[pack].price_list.find(({name}) => name === package_name) || {}
          const price = price_package.price[duration]
          total += price
        }
      }
      return total
    },
    // canSubscribe() {
    //   return (this.businessSubscription && this.businessSubscription.length > 0 && moment(businessSubscription[0]?.expires_at).isBefore(moment(new Date)))
    //   || !this.v$.subscription.$invalid
    // },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  created() {
    this.formatter = formatter
    this.moment = moment
  },
  async mounted() {
    this.subscription.frequency = this.frequencies[0]
    Object.entries(this.packages).forEach(([k,]) => {
      const existingSubscription = (this.businessSubscription || []).find( s => s.product === k) || {}
      this.subscription.duration[k] = existingSubscription.duration ?? this.durations.MONTH
      this.subscription[k] = existingSubscription.package ?? "Basic"
      if (existingSubscription.frequency) {
        this.subscription.frequency = existingSubscription.frequency
      }
    })
  },
  validations() {
    return {
      subscription: {
        savings: { required },
        loan: { required },
        investment: { required },
      }
    }
  },
  methods: {
    ...mapActions(["addSubscription"]),
    async subscribe() {
      confirmAction({
        confirmText: `Confirm to proceed.`,
        confirmTextHelper: `Confirm to proceed with this subscription of ${Object.keys(this.subscription.duration).join(", ")} plan. Charges will be ${this.subscription.frequency}`,
        successText: "This transaction has been marked as successfull",
        cancelText: "This action was aborted"
      }, async () => {
        const response = await this.addSubscription(this.subscription)
        const { payment } = response
        const { reference, access_code, account_number, account_name, bank_name } = payment
        console.log(response)
        if (reference) {
          if (access_code) {
            const popup = new PaystackPop()
            popup.resumeTransaction(access_code)
          } else if (account_number && account_name && bank_name) {
            this.completeManualPayment(account_number, account_name, bank_name)
          }
        }
      }, null, false); 
    },
    completeManualPayment(account_number, account_name, bank_name) {
      confirmAction({
        confirmText: `Make payment to the following account.`,
        confirmTextHelper: `Account Number: ${account_number}. Account Name: ${account_name}. Bank Name: ${bank_name}`,
        successText: "This transaction is being processed. Kindly give us 2hrs to complete the transaction.",
        cancelText: "You have aborted this payment. Hope to see you soon.",
        confirmButtonText: "I have made payment"
      }, async () => {
        // send mail here 
      }); 
    }
  }
};
</script>
