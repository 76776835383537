import http from "./http";

export const GetSubscription = async() => {
    return await http.get("/subscription/business");
};


export const AddSubscription = async data => {
    return await http.post("/subscription/business", data);
};

export const UpdateSubscription = async(id, data) => {
    return await http.put(`/subscription/business/${id}`, data);
};