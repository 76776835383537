<template>
    <button :class="`btn ${ classes || 'btn-success w-100'}`" :disabled="disabled || false" type="button">
        <div v-if="loading" class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div>
        <div v-else>
            <slot></slot> {{ title || 'Submit' }}
        </div>
    </button>
</template>

<script>

export default {
  name: 'AppButton',
  props: ['title', 'loading', 'disabled', 'classes'],
  methods: {
      clicked(e) {
          this.$emit('click', e);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
