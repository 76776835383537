<template>
  <div
    id="map-canvas"
    class="relative w-full rounded h-600-px"
    :data-lat="latitude"
    :data-lng="longitude"
  ></div>
</template>
<script>
export default {
  props: {
    latitude: {
      type: String,
      required: false,
      default: "40.748817"
    }, 
    longitude: {
      type: String,
      required: false,
      default: "-73.985428"
    }
  },
  watch: {
    latitude: function (latitude) {
      this.lat = latitude;
      const self = this;
      setTimeout(() => {
        self.position()
      }, 500);
    },
    longitude: function (longitude) {
      this.lng = longitude;
      const self = this;
      setTimeout(() => {
        self.position()
      }, 500);
    },
  },
  data() {
    return {
      lng: 0,
      lat: 0
    }
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      let map = document.getElementById("map-canvas");
      self.lng = self.latitude || map.getAttribute("data-lat") || 0;
      self.lat = self.longitude || map.getAttribute("data-lng") || 0;
      self.position()
    }, 500);
  },
  methods: {
    position() {
      let google = window.google;
      let map = document.getElementById("map-canvas");
      let lat = this.lat;
      let lng = this.lng;

      const myLatlng = new google.maps.LatLng(lat, lng);
      const mapOptions = {
        zoom: 12,
        scrollwheel: false,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          },
        ],
      };

      map = new google.maps.Map(map, mapOptions);

      const marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: "Customer position!",
      });

      const contentString =
        '<div class="info-window-content"><h2>Redge</h2>' +
        "<p>Here is customer current location</p></div>";

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(map, marker);
      });
    }
  }
};
</script>
