export const vendAirtime = (state, airtime) => {
    state.transactions.data = [
        airtime.transaction,
        ...state.transactions.data
    ]
};
export const fetchData = (state, data) => {
    state.vasdata = data;
};
export const vendData = (state, data) => {
    state.transactions.data = [
        data.transaction,
        ...state.transactions.data
    ]
};
export const fetchPower = (state, power) => {
    state.vaspower = power;
};
export const validatePower = (_, power) => {
    console.log(power)
};
export const vendPower = (state, power) => {
    state.transactions.data = [
        power.transaction,
        ...state.transactions.data
    ]
};
export const fetchCable = (state, cable) => {
    state.vascable = cable;
};
export const validateCable = (_, cable) => {
    console.log(cable)
};
export const vendCable = (state, cable) => {
    state.transactions.data = [
        cable.transaction,
        ...state.transactions.data
    ]
};



const VasMutation = {
    vendAirtime,
    fetchData,
    vendData,
    fetchPower,
    validatePower,
    vendPower,
    fetchCable,
    validateCable,
    vendCable
};

export default VasMutation;