<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
          <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
            <li class="-mb-px mr-2 last:mr-0" v-if="has('plan.view.ability')">
              <product-package package="standard">
                <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('payments')" v-bind:class="{'text-emerald-600 bg-white': activeGateway !== 'payments', 'text-white bg-orange-500': activeGateway === 'payments'}">
                  <i class="pi pi-warehouse"></i> Payment Gateway
                </a>
              </product-package>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('plans.show')">
              <product-package package="standard">
                <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('vas')" v-bind:class="{'text-emerald-600 bg-white': activeGateway !== 'vas', 'text-white bg-orange-500': activeGateway === 'vas'}">
                  <i class="pi pi-receipt"></i> VAS Services
                </a>
              </product-package>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('plans.show')">
              <product-package package="standard">
                <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('sme_vas')" v-bind:class="{'text-emerald-600 bg-white': activeGateway !== 'sme_vas', 'text-white bg-orange-500': activeGateway === 'sme_vas'}">
                  <i class="pi pi-receipt"></i> Business VAS Services
                </a>
              </product-package>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('plans.show')">
              <product-package package="standard">
                <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('messages')" v-bind:class="{'text-emerald-600 bg-white': activeGateway !== 'messages', 'text-white bg-orange-500': activeGateway === 'messages'}">
                  <i class="pi pi-inbox"></i> Message Gateway
                </a>
              </product-package>
            </li>
            <li class="-mb-px mr-2 last:mr-0" v-if="has('plans.show')">
              <product-package package="standard">
                <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer" v-on:click="toggleTabs('verifications')" v-bind:class="{'text-emerald-600 bg-white': activeGateway !== 'verifications', 'text-white bg-orange-500': activeGateway === 'verifications'}">
                  <i class="pi pi-verified"></i> Verification Services
                </a>
              </product-package>
            </li>
          </ul>
        </div>
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
          <div class="flex-auto">
            <div class="tab-content tab-space">
              <div class="flex flex-wrap block">
                <transition name="slide-fade">
                  <div class="w-full" :class="{'lg:w-8/12': Object.values(gateway).length > 0}">
                    <card-gateway-payment :gateways="gateways[activeGateway]" :type="activeGateway" @selectedGateway="changePaymentGateway"></card-gateway-payment>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div class="w-full lg:w-4/12 px-4" v-if="Object.values(gateway).length > 0">
                    <card-gateway-payment-form :selectedGateway="gateway" @close="handleCloseGateway"></card-gateway-payment-form>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardGatewayPayment from "@/components/Cards/CardGatewayPayment.vue";
import CardGatewayPaymentForm from "@/components/Cards/CardGatewayPaymentForm.vue";
import { mapActions, mapGetters } from 'vuex';
import flutterwaveLogo from "@/assets/img/flutterwave.png";
import vpayLogo from "@/assets/img/vpay.png";
import paystackLogo from "@/assets/img/paystack.png";
import monnifyLogo from "@/assets/img/monnify.png";
import providusLogo from "@/assets/img/providus.png";
import korapayLogo from "@/assets/img/korapay.png";
import kudaLogo from "@/assets/img/kuda.png";
import irechargeLogo from "@/assets/img/irecharge.png";
import vtpassLogo from "@/assets/img/vtpass.png";
import termiiLogo from "@/assets/img/termii.png";
import sleengshortogo from "@/assets/img/sleengshort.png";
import verifymeLogo from "@/assets/img/verifyme.png";
import premblyLogo from "@/assets/img/prembly.png";
import dotpayLogo from "@/assets/img/dotpay.png";
import emailLogo from "@/assets/img/email.png";
import swiftdatalinkLogo from "@/assets/img/swiftdatalink.png";
import ProductPackage from "@/components/Cards/ProductPackage.vue";

export default {
  components: {
    CardGatewayPayment,
    CardGatewayPaymentForm,
    ProductPackage
  },
  data() {
    return {
      gateways: {
        payments: {
          dotpay: {
            logo: dotpayLogo,
            name: "DotPay",
            cred: {
              DOTPAY_CLIENT_KEY: {required: true, type: "text", minLength: 3},
              DOTPAY_SECRET_KEY: {required: true, type: "text", minLength: 16},
              DOTPAY_SOURCE_ACCOUNT: {required: true, type: "text", minLength: 10},
              DOTPAY_SOURCE_ACCOUNT_NAME: {required: true, type: "text", minLength: 10},
              DOTPAY_BVN: {required: true, type: "text", minLength: 10},
              // DOTPAY_SOURCE_ACCOUNT: {required: true, type: "text", minLength: 10},
              DOTPAY_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          paystack: {
            logo: paystackLogo,
            name: "Paystack",
            cred: {
              PAYSTACK_PUBLIC_KEY: {required: true, type: "text", minLength: 45},
              PAYSTACK_SECRET_KEY: {required: true, type: "text", minLength: 45},
              PAYSTACK_ENCRYPTION_KEY: {required: false, type: "text", minLength: 22},
              PAYSTACK_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          flutterwave: {
            logo: flutterwaveLogo,
            name: "Flutterwave",
            cred: {
              FLUTTERWAVE_PUBLIC_KEY: {required: true, type: "text", minLength: 45},
              FLUTTERWAVE_SECRET_KEY: {required: true, type: "text", minLength: 45},
              FLUTTERWAVE_ENCRYPTION_KEY: {required: false, type: "text", minLength: 22},
              FLUTTERWAVE_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          vpay: {
            logo: vpayLogo,
            name: "V-Pay",
            cred: {
              VPAY_PUBLIC_KEY: {required: true, type: "text", minLength: 35},
              VPAY_SECRET_KEY: {required: true, type: "text", minLength: 35},
              VPAY_ENCRYPTION_KEY: {required: false, type: "text", minLength: 10},
              V_PAY_USERNAME: {required: true, type: "text", minLength: 3},
              V_PAY_PASSWORD: {required: true, type: "text", minLength: 8},
              VPAY_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          monnify: {
            logo: monnifyLogo,
            name: "Monnify",
            cred: {
              MONNIFY_API_KEY: {required: true, type: "text", minLength: 15},
              MONNIFY_SECRET_KEY: {required: true, type: "text", minLength: 32},
              MONNIFY_CONTRACT_CODE: {required: true, type: "text", minLength: 12},
              MONNIFY_SOURCE_ACCOUNT: {required: true, type: "text", minLength: 10},
              MONNIFY_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          providus: {
            logo: providusLogo,
            name: "Providus",
            cred: {
              PROVIDUS_CLIENT_ID: {required: true, type: "text", minLength: 18},
              PROVIDUS_CLIENT_SECRET: {required: true, type: "text", minLength: 64},
              PROVIDUS_X_AUTH_SIGNATURE: {required: true, type: "text", minLength: 64},
              PROVIDUS_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          korapay: {
            logo: korapayLogo,
            name: "Korapay",
            cred: {
              KORAPAY_PUBLIC_KEY: {required: true, type: "text", minLength: 32},
              KORAPAY_SECRET_KEY: {required: true, type: "text", minLength: 32},
              KORAPAY_ENCRYPTION_KEY: {required: true, type: "text", minLength: 26},
              KORAPAY_FEE: {required: true, type: "number", minLength: 1}
            }
          },
          kuda: {
            logo: kudaLogo,
            name: "Kuda",
            cred: {
              KUDA_PUBLIC_KEY: {required: true, type: "text", minLength: 18},
              KUDA_SECRET_KEY: {required: true, type: "text", minLength: 128},
              KUDA_ENCRYPTION_KEY: {required: true, type: "text", minLength: 128},
              KUDA_FEE: {required: true, type: "number", minLength: 1}
            }
          }
        },
        vas: {
          irecharge: {
            logo: irechargeLogo,
            name: "IRecharge",
            cred: {
              IRECHARGE_VENDOR_CODE: {required: true, type: "text", minLength: 10},
              IRECHARGE_PUBLIC_KEY: {required: true, type: "text", minLength: 30},
              IRECHARGE_PRIVATE_KEY: {required: true, type: "text", minLength: 128},
            }
          },
          vtpass: {
            logo: vtpassLogo,
            name: "VTPass",
            cred: {
              VTPASS_USERNAME: {required: true, type: "text", minLength: 3},
              VTPASS_PASSWORD: {required: true, type: "text", minLength: 8},
              VTPASS_API_KEY: {required: true, type: "text", minLength: 16},
              VTPASS_SECRET_KEY: {required: true, type: "text", minLength: 16},
            }
          },
          mobilefoot: {
            logo: irechargeLogo,
            name: "MobileFoot",
            cred: {
              MOBILEFOOT_TOKEN: {required: true, type: "text", minLength: 10},
              MOBILEFOOT_DATA_RESELLER_PRICE: {required: true, type: "number", minLength: 1},
              MOBILEFOOT_DATA_PRICE: {required: true, type: "number", minLength: 1}
            }
          },
          swiftdatalink: {
            logo: swiftdatalinkLogo,
            name: "Swift Data Link",
            cred: {
              SWIFTDATALINK_TOKEN: {required: true, type: "text", minLength: 50},
              SWIFTDATALINK_PIN: {required: true, type: "text", minLength: 4},
              SWIFTDATALINK_DATA_RESELLER_PRICE: {required: true, type: "number", minLength: 1},
              SWIFTDATALINK_DATA_PRICE: {required: true, type: "number", minLength: 1}
            }
          }
        },
        sme_vas: {
          mobilefoot: {
            logo: irechargeLogo,
            name: "MobileFoot",
            cred: {
              MOBILEFOOT_TOKEN: {required: true, type: "text", minLength: 10},
              MOBILEFOOT_DATA_RESELLER_PRICE: {required: true, type: "number", minLength: 1},
              MOBILEFOOT_DATA_PRICE: {required: true, type: "number", minLength: 1}
            }
          },
          swiftdatalink: {
            logo: swiftdatalinkLogo,
            name: "Swift Data Link",
            cred: {
              SWIFTDATALINK_TOKEN: {required: true, type: "text", minLength: 50},
              SWIFTDATALINK_PIN: {required: true, type: "text", minLength: 4},
              SWIFTDATALINK_DATA_RESELLER_PRICE: {required: true, type: "number", minLength: 1},
              SWIFTDATALINK_DATA_PRICE: {required: true, type: "number", minLength: 1}
            }
          }
        },
        messages: {
          termii: {
            logo: termiiLogo,
            name: "Termii",
            cred: {
              TERMII_API_KEY: {required: true, type: "text", minLength: 32},
              TERMII_SENDER_ID: {required: true, type: "text", minLength: 3}
            }
          },
          sleegshort: {
            logo: sleengshortogo,
            name: "SleegShort",
            cred: {
              SLEEGSHORT_KEY: {required: true, type: "text", minLength: 32},
              SLEEGSHORT_SENDER_ID: {required: true, type: "text", minLength: 3}
            }
          },
          smtp: {
            logo: emailLogo,
            name: "EMail Service",
            cred: {
              MAIL_HOST: {required: true, type: "text", minLength: 3},
              MAIL_PORT: {required: true, type: "number", minLength: 3},
              MAIL_ENCRYPTION: {required: false, type: "text", minLength: 8},
              MAIL_USERNAME: {required: true, type: "text", minLength: 3},
              MAIL_PASSWORD: {required: true, type: "text", minLength: 8}
            }
          },
          // nuobject: {
          //   logo: irecharge,
          //   name: "NuObject",
          //   cred: {
          //     NU_OBJECT_USERNAME: {required: true, type: "text", minLength: 3},
          //     NU_OBJECT_PASSWORD: {required: true, type: "text", minLength: 8},
          //   }
          // }
        },
        verifications: {
          verifyme: {
            logo: verifymeLogo,
            name: "Verify Me",
            cred: {
              VERIFY_ME_PUB_KEY: {required: true, type: "text", minLength: 32},
              VERIFY_ME_SECRET_KEY: {required: true, type: "text", minLength: 3}
            }
          },
          prembly: {
            logo: premblyLogo,
            name: "Prembly",
            cred: {
              PREMBLY_PUB_KEY: {required: true, type: "text", minLength: 12},
              PREMBLY_PRIV_KEY: {required: true, type: "text", minLength: 12},
              PREMBLY_APP_ID: {required: true, type: "text", minLength: 8}
            }
          },
        }
      },
      activeGateway: 'payments',
      param: {},
      gateway: {}
    }
  },
  mounted() {
    this.getGatewayConfigs();
  },
  methods: {
    ...mapActions(["getGatewayConfigs"]),
    ...mapGetters(["gatewayConfigs"]),
    changePaymentGateway(gateway) {
      this.gateway = gateway
    },
    toggleTabs: function(gateway){
      this.activeGateway = gateway
      this.handleCloseGateway()
    },
    handleCloseGateway(){
      this.gateway = {}
    }
  }
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
