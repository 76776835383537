<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->
    <div class="rounded-t bg-white mb-0 px-3 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-500 text-lg">Business</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    <div class="flex-auto lg:px-6 py-10 pt-0">
      <div class="px-4 mt-4">
        <i class="pi pi-building-columns text-sm"></i> 
        {{ business.address }}, {{ business.city }}, {{ business.country }}
      </div>
      <form v-if="has('business.update') || has('business.confirm.update')">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ business.phone }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.name.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Loan Commission (%)
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.loan_commission.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.loan_commission.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Savings Commission (%)
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.savings_commission.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.savings_commission.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                LGA
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.lga.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.lga.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                State
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.state.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.state.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Whatsapp Link
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.whatsapp_link.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.whatsapp_link.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Webhook Url
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.webhook_url.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.webhook_url.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Status
              </label>
              <select
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.status.$model"
              >
                <option v-for="(option, i) in ['PENDING', 'DECLINE', 'EXPIRED', 'REJECTED', 'APPROVED', 'BLOCKED', 'PARTIALLY_BLOCKED']" :key="i" :value="option">
                  {{ option }}
                </option>
              </select>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business.status.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative w-full mb-4 px-4 mt-2 justify-between">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="persistBusiness" 
            :disabled="loaders().updateBusiness || v$.business.$invalid"
            v-if="has('business.update') || has('business.confirm.update')"
          >
            <span v-if="loaders().updateBusiness" class="flex loader loader-sm"></span><span v-else>Update Business</span>
          </button>

          <button
            class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="removeBusiness" 
            :disabled="loaders().deleteBusiness || !business.id"
            v-if="has('business.destroy')"
          >
            <span v-if="loaders().deleteBusiness" class="flex loader loader-sm"></span><span v-else>Delete Business</span>
          </button>

          <button 
            class="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
            v-on:click="toggleModal('addManager', true)"
            :disabled="loaders().addBusinessManager"
            v-if="has('business.manager.create')"
          >
            <span v-if="loaders().addBusinessManager" class="flex loader loader-sm"></span><span v-else>Add Manager</span>
          </button>
        </div>
      </form>
      <form v-if="has('business.confirm.update')">
        <div class="relative w-full mb-4 px-4 mt-2 justify-between">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="() => { business.status = 'APPROVED'; persistBusiness() }" 
            :disabled="loaders().updateBusiness || v$.business.$invalid"
          >
            <span v-if="loaders().updateBusiness" class="flex loader loader-sm"></span><span v-else>Approve Business</span>
          </button>
        </div>
      </form>
    </div>

    <div v-if="modals.addManager" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Add Manager
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('addManager', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form v-if="has('business.manager.create')">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 p-4 font-bold uppercase">
                Search User
              </h6>
              <div class="flex flex-wrap">

                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Users
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                      v-model="v$.search.$model" 
                      @keyup="searchUser"
                      placeholder="Search user"
                    />
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.search.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    
                    <select v-model="v$.user.$model" class="border-0 mt-4 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                      <option v-for='(_user, i) in users().data' :key="i" :value="_user" >{{ _user.name }}</option>
                    </select>
                  </div>
                </div> 
              </div>

              <div class="relative w-full mb-3 px-4 mt-8">
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                  type="button" 
                  @click="handleAddBusinessMananger" 
                  :disabled="loaders().addBusinessManager"
                >
                  <span v-if="loaders().addBusinessManager" class="flex loader loader-sm"></span><span v-else>Add Manager</span>
                </button>
              </div>
            </form>
            <div v-else>
              You do not have the permission to add manager
            </div>

          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">

            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('addManager', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.addManager" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: [ "activeBusiness" ],
  components: {},
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      business: this.activeBusiness,
      modals: {},
      search: "",
      user: {},
      isMapVisible: false
    }
  },
  validations() {
    return {
      business: {
        name: { required },
        savings_commission: {},
        loan_commission: {},
        status: { required },
        webhook_url: {},
        lga: {},
        state: {},
        whatsapp_link: {}
      },
      search: {},
      user: {}
    }
  },
  watch: {
    activeBusiness: function(business) {
      this.business = business
      this.$forceUpdate()
    }
  },
  methods: {
    ...mapGetters(["loaders", "users"]),
    ...mapActions([
      "updateBusiness",
      "deleteBusiness",
      "getAllUsers",
      "addBusinessManager"
    ]),
    async persistBusiness() {
      const data = this.business;
      await this.updateBusiness(data)
    },
    async removeBusiness() {
      if (this.business.id && confirm("Are you sure you want to delete this business?")) {
        await this.deleteBusiness(this.business.id)
      }
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    searchUser() {
      const self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        self.getAllUsers({search: self.search})
      }, 1000)
    },
    handleAddBusinessMananger() {
      const data = {
        business_id: this.business.id,
        user_id: this.user.id,
        // partner: 'business_manager'
      }
      const self = this;
      this.addBusinessManager(data).then( () => {
        self.business.manager = self.user
      }).catch( () => {
        toaster.error("An error occored!")
      })
      
    }
  }
}
</script>