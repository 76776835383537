import http from "./http";

export const GetMyAccount = async () => await http("/accounts");
export const FundAccount = async (data) => await http.post("/accounts/fund", data);










export const CreateAccount = async (account) => await http.post("account/accounts", account)
export const UpdateAccount = async (id, account) => await http.put("account/accounts/" + id, account);



/**
 * Setting
 */
export const GetAllSetting = async () => {
  return await http("account/settings");
};

export const GetSetting = async (id) => {
  return await http("account/settings/" + id);
};

export const CreateSetting = async (setting) => {
  return await http.post("account/settings", setting);
};

export const CreateSettingPin = async (setting) => {
  return await http.post("account/settings/pin/create", setting);
};

export const VerifySettingPin = async (setting) => {
  return await http.post("account/settings/pin/create", setting);
};

export const UpdateSetting = async (id, setting) => {
  return await http.patch("account/settings/" + id, setting);
};

export const DeleteSetting = async (id) => {
  return await http.delete("account/settings/" + id);
};

export const VerifyViaBVN = async(data) => {
  return await http.post("verify/bvn", data);
};