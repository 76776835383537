<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-4 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs font-bold">{{ gateway.name }}</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
          <i class="pi pi-cog"></i> Setup Configuration
        </h6>
        <div class="flex flex-wrap">
          <div class="relative w-full mb-3 flex">
            <img :src="selectedGateway.logo" width="25" class="mr-2 rounded" /> {{ selectedGateway.name }}
          </div>
        </div>

        <hr class="my-2 border-b-1 border-blueGray-300" />


        <div class="flex flex-wrap">
          <div class="w-full px-1" v-for="([key, feild], i) in Object.entries(selectedGateway.cred)" :key="i">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                {{ key }}
              </label>
              <input
                :type="feild.type"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.gateway[key].$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.gateway[key].$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mb-3 px-1 mt-3">
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            <i class="pi pi-ban text-xs"></i> Cancel
          </button>
          <app-button
            :classes="`${v$.gateway.$invalid || loaders().addGatewayConfig ? 'bg-blueGray-100 active:bg-blueGray-300 text-blueGray-700' : 'bg-emerald-500 active:bg-emerald-600 text-white'} text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`"
            type="button"
            :disabled="loaders().addGatewayConfig || v$.gateway.$invalid"
            :loading="loaders().addGatewayConfig"
            @click="persistConfig"
            title="Update"
          >
          <i class="pi pi-refresh text-xs"></i>
          </app-button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/Form/AppButton.vue';
import { strings } from '@/mixins'

export default {
  props: [ "selectedGateway" ],
  components: {
    AppButton
  },
  mixins: [strings],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedGateway: function(gateway) {
      this.loadCredData(gateway)
    }
  },
  data() {
    return {
      gateway: {},
    }
  },
  mounted() {
    this.loadCredData(this.selectedGateway)
  },
  validations() {
    const validations = {
      gateway: {}
    }

    for (const k in this.selectedGateway?.cred || {}) {
      const c = this.selectedGateway?.cred[k]
      validations.gateway[k] = { min: minLength(c.minLength) }
      if (c.required ) {
        validations.gateway[k] = { required, ...validations.gateway[k]}
      }
    }
    
    return validations
  },
  methods: {
    ...mapGetters(["loaders", "gatewayConfigs"]),
    ...mapActions(["addGatewayConfig"]),
    async persistConfig() {
      const provider = this.selectedGateway.name.replace(/[^a-z0-9]/gi, '').toLowerCase()
      const payload = [];
      for (const k in this.gateway) {
        payload.push({
          provider,
          key: k,
          value: this.gateway[k],
          active: true
        })
      }

      try {
        await this.addGatewayConfig(payload);
      } catch (e) {
        console.log(e)
      }
    },
    loadCredData(gateway) {
      const data = this.gatewayConfigs()
      const params = {}
      for (const cred of data ) {
        if ( cred.provider ===  gateway.name?.replace(/[^a-z0-9]/gi, '').toLowerCase() ) {
          params[cred.key] = cred.value
        }
      }
      this.gateway = params
    }
  }
}
</script>