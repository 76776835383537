import {
    FetchFees,
    SaveFee,
    UpdateFee,
    DeleteFee
} from "@/services/fee";

export const fetchFees = (context, data = {}) => {
    context.commit("updateLoader", { fetchFees: true });
    return new Promise((resolve, reject) => {
        FetchFees(data)
            .then(res => {
                const result = res.data.data
                if (data && data.get_csv) {
                    resolve(result)
                } else {
                    context.commit("fetchFees", result);
                    resolve(result)
                }
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchFees: false });
            });
    });
}

export const saveFee = (context, data) => {
    context.commit("updateLoader", { saveFee: true });
    return new Promise((resolve, reject) => {
        SaveFee(data)
            .then(res => {
                const result = res.data
                context.commit("saveFee", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { saveFee: false });
            });
    });
}

export const updateFee = (context, data) => {
    context.commit("updateLoader", { updateFee: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        UpdateFee(id, data)
            .then(res => {
                const result = {...res.data, id}
                context.commit("updateFee", result);
                resolve(result)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateFee: false });
            });
    });
}

export const deleteFee = (context, data) => {
    context.commit("updateLoader", { deleteFee: true });
    const { id } = data;
    return new Promise((resolve, reject) => {
        DeleteFee(id)
            .then(res => {
                const result = res.data.data
                context.commit("deleteFee", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteFee: false });
            });
    });
}

const FeeAction = {
    fetchFees,
    saveFee,
    updateFee,
    deleteFee
};

export default FeeAction;