import { FetchCable, FetchData, FetchPower, ValidateCable, ValidatePower, VendAirtime, VendCable, VendData, VendPower } from "@/services/vas";

export const vendAirtime = (context, data) => {
    context.commit("updateLoader", { vendAirtime: true });
    return new Promise((resolve, reject) => {
        VendAirtime(data)
            .then((res) => {
                context.commit("vendAirtime", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { vendAirtime: false });
            });
    });
};

export const fetchData = (context, network) => {
    context.commit("updateLoader", { fetchData: true });
    return new Promise((resolve, reject) => {
        FetchData(network)
            .then((res) => {
                context.commit("fetchData", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchData: false });
            });
    });
};

export const vendData = (context, data) => {
    context.commit("updateLoader", { vendData: true });
    return new Promise((resolve, reject) => {
        VendData(data)
            .then((res) => {
                context.commit("vendData", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { vendData: false });
            });
    });
};

export const fetchPower = (context, data) => {
    context.commit("updateLoader", { fetchPower: true });
    return new Promise((resolve, reject) => {
        FetchPower(data)
            .then((res) => {
                context.commit("fetchPower", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchPower: false });
            });
    });
};

export const validatePower = (context, data) => {
    context.commit("updateLoader", { validatePower: true });
    return new Promise((resolve, reject) => {
        ValidatePower(data)
            .then((res) => {
                const response = res.data.data
                context.commit("validatePower", response);
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { validatePower: false });
            });
    });
};

export const vendPower = (context, data) => {
    context.commit("updateLoader", { vendPower: true });
    return new Promise((resolve, reject) => {
        VendPower(data)
            .then((res) => {
                const response = res.data.data
                context.commit("vendPower", response);
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { vendPower: false });
            });
    });
};
export const fetchCable = (context, service) => {
    context.commit("updateLoader", { fetchCable: true });
    return new Promise((resolve, reject) => {
        FetchCable(service)
            .then((res) => {
                context.commit("fetchCable", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchCable: false });
            });
    });
};

export const validateCable = (context, data) => {
    context.commit("updateLoader", { validateCable: true });
    return new Promise((resolve, reject) => {
        ValidateCable(data)
            .then((res) => {
                const response = res.data.data
                context.commit("validateCable", response);
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { validateCable: false });
            });
    });
};

export const vendCable = (context, data) => {
    context.commit("updateLoader", { vendCable: true });
    return new Promise((resolve, reject) => {
        VendCable(data)
            .then((res) => {
                const response = res.data.data
                context.commit("vendCable", response);
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { vendCable: false });
            });
    });
};


const VasAction = {
    vendAirtime,
    fetchData,
    vendData,
    fetchPower,
    validatePower,
    vendPower,
    fetchCable,
    validateCable,
    vendCable
};

export default VasAction;