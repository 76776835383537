<template>
  <div class="flex flex-wrap w-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16 mb-16"
      >
        <div class="px-6 py-6">
          <h3
            class="text-lg leading-normal mb-2 text-blueGray-700"
          >
            Send Us An Email <i class="pi pi-thumbs-up"></i>
          </h3>
          <div class="mb-2 text-blueGray-600 cursor-pointer mt-10">
            <i class="pi pi-envelope"></i> <a href="mailto:admin@redge.ng">admin@redge.ng</a>
          </div>
          <div class="mb-2 text-blueGray-600 cursor-pointer">
            <i class="pi pi-envelope"></i> <a href="mailto:management@redge.ng">management@redge.ng</a>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16 mb-16"
      >
        <div class="px-6 py-6">
          <h3
            class="text-lg leading-normal mb-2 text-blueGray-700"
          >
            Call Us On
          </h3>
          <div class="mb-2 text-blueGray-600 mt-10">
            <i class="pi pi-phone"></i> <a href="mailto:0802">08023456789</a>
          </div>
          <div class="mb-2 text-blueGray-600">
            <i class="pi pi-phone"></i> <a href="mailto:0802">0802</a>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16 mb-16"
      >
        <div class="px-6 py-6">
          <h3
            class="text-lg leading-normal mb-2 text-blueGray-700"
          >
            Chat With Us
          </h3>
          <div class="mb-2 text-blueGray-600 mt-10">
            <i class="pi pi-comments"></i> chat
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  data() {
    return {
      team2,
    };
  }
};
</script>
