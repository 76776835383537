import http from "./http";

export const FetchFees = async params => {
    return await http.get("/fees", {params});
}

export const SaveFee = async data => {
    return await http.post("/fees", data);
}

export const UpdateFee = async(id, data) => {
    return await http.put(`/fees/${id}`, data);
}

export const DeleteFee = async id => {
    return await http.delete(`/fees/${id}`);
}
