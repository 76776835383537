<template>
    <div>
        <div v-if="visible" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
            <div class="relative my-6 mx-auto" style="min-width: 30%;">
                <!--content-->
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-lg">
                    Filter {{ title }}
                    </h3>
                    <button 
                    class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                    v-on:click="closeModal"
                    >
                    <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                    </button>
                </div>
                
                <form class="mt-4 px-4">
                    <div class="w-full px-4" v-if="searchable">
                        <div class="relative w-full mb-3">
                            <label
                                class="block text-blueGray-600 text-xs capitalize font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Search
                            </label>
                            <input 
                                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                                @keyup="searchClient" 
                                v-model="filter.search"
                                :disabled="loading"
                            />
                        </div>
                    </div>

                    <div class="w-full px-4" v-for="(f, i) in feilds" :key="i">
                        <div class="relative w-full mb-3">
                            <label
                                class="block text-blueGray-600 text-xs capitalize font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                {{ f }}
                            </label>
                            <input 
                                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                                @keyup="searchClient" 
                                v-model="filter[f]"
                                :disabled="loading"
                                type="text"
                            />
                        </div>
                    </div>

                    <div class="w-full" v-for="([name, options], i) in Object.entries(selects || {})" :key="i">
                        <div class="w-full px-4">
                            <div class="relative w-full mb-3 mt-3">
                            <label
                                class="block text-blueGray-600 capitalize text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                {{ name.split('_').join(' ') }}
                            </label>
                            <select 
                                v-model="filter[name]" 
                                :disabled="loading" 
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 capitalize bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                                @change="searchClient"
                            >
                                <template v-for='(option, j) in options'>
                                    <option v-if="option && typeof option === 'string'" :value="option" :key="j" class="capitalize">{{ snakeToTitleCase(option) }}</option>
                                    <option v-else :value="option.key" :key="`1_${j}`" class="capitalize">{{ snakeToTitleCase(option.value) }}</option>
                                </template>
                            </select>
                            </div>
                        </div> 
                    </div>

                    <div class="w-full" v-for="([id, label], i) in Object.entries(dates || {})" :key="i">
                        <div class="w-full px-4">
                            <div class="relative w-full mb-3 mt-3">
                                <label
                                    class="block text-blueGray-600 capitalize text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    {{ label }}
                                </label>
                                <input 
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                                    :id="`${id}_${title}`"
                                    type="text"
                                    placeholder="19-1-1979"
                                    @change="searchClient"
                                />
                            </div>
                        </div> 
                    </div>

                    <div class="w-full px-4" v-if="sizable">
                        <label
                            class="block text-blueGray-600 capitalize text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            Per page
                        </label>
                        <div class="relative w-full mb-3">
                            <select 
                                v-model="filter.size" 
                                :disabled="loading" 
                                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                                @change="searchClient"
                            >
                                <option v-for='(cur, i) in [10, 15, 20, 50, 10]' :key="i" >{{ cur }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="relative w-full mb-3 px-4 mt-2">
                        <button 
                            class="bg-red-500 text-white active:bg-red-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" 
                            v-on:click="closeModal"
                        >
                            Cancel
                        </button>
                        <app-button 
                            :classes="'bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150'" 
                            @click="applyFilter" 
                            title="Apply filter" 
                            :loading="loading" 
                            :disabled="loading" 
                        >
                        <i class="pi pi-filter text-sm" style="font-size: 10px;"></i>    
                        </app-button>
                    </div>
                </form>

                <div class="flex items-center justify-end border-t border-solid border-blueGray-200 rounded-b px-4">
                    <button 
                        class="text-red-500 background-transparent px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                        v-on:click="closeModal"
                    >
                        Close
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div v-if="visible" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
    </div>
</template>

<script>
import AppButton from '@/components/Form/AppButton.vue';
import datepicker from 'js-datepicker'
import moment from "moment"
import { snakeToTitleCase } from "@/utils"

export default {
    components: {
        AppButton
    },
    props: {
        title: {
            type: String,
            default: 'Client',
            required: false
        },
        feilds: {
            type: Array,
            required: false,
            default: () => []
        },
        selects: {
            type: Object,
            required: false
        },
        dates: {
            type: Object,
            required: false,
            default: () => {},
        },
        visible: {
            type: Boolean,
            required: true
        },
        sizable: {
            type: Boolean,
            required: true
        },
        searchable: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        searchClient() {
            const self = this
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                self.applyFilter(false)
            }, 1000)
        },
        applyFilter(close = true) {
            this.$emit('filter', this.filter)
            if (close) this.$emit('close')
        },
        loadDates() {
            for (const id in this.dates) {
                datepicker(`#${id}_${this.title}`, {
                    id,
                    onSelect: (_, date) => {
                        this.filter[id] = moment(date || new Date).format("YYYY-MM-DD");
                    },
                    // disabler: date => moment(new Date).isBefore(moment(date))
                });
            }
        }
    },
    data() {
        return {
            filter: {},
            snakeToTitleCase
        }
    },
    watch: {
        'visible': function(o) {
            if (o) {
                const self = this
                setTimeout( function() {
                    self.loadDates()
                }, 1000)
            }
        },
    },
    mounted() {
        
    }
}
</script>