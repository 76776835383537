export const fetchTenant = (
  state, tenant
) => {
  // console.log(tenant)
  if (tenant && typeof tenant === "object") {
    localStorage.setItem("tenant", JSON.stringify(tenant))
  }
  state.tenant = tenant;
}


const AppMutation = {
  fetchTenant
};

export default AppMutation;
