<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div class="flex-auto">
          <div class="tab-content tab-space">
            <div class="flex flex-wrap">
              <div class="w-full">
                <card-provider-log />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full mb-12 px-4">
      <card-transaction color="dark" />
    </div> -->
  </div>
</template>
<script>
import CardProviderLog from "@/components/Cards/CardProviderLog.vue";

export default {
  components: {
    CardProviderLog,
  }
};
</script>
