<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(campaignList[0])" v-bind:class="{'text-emerald-600 bg-white': openTab !== campaignList[0], 'text-white bg-orange-500': openTab === campaignList[0]}">
              <i class="pi pi-send text-sm" style="font-size: 10px;"></i> Push Notification
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(campaignList[1])" v-bind:class="{'text-emerald-600 bg-white': openTab !== campaignList[1], 'text-white bg-orange-500': openTab === campaignList[1]}">
              <i class="pi pi-inbox text-sm" style="font-size: 10px;"></i> Email
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0">
            <product-package package="premium">
              <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(campaignList[2])" v-bind:class="{'text-emerald-600 bg-white': openTab !== campaignList[2], 'text-white bg-orange-500': openTab === campaignList[2]}">
                <i class="pi pi-envelope text-sm" style="font-size: 10px;"></i> SMS
              </a>
            </product-package>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div class="flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== campaignList[0], 'block': openTab === campaignList[0]}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12">
                <card-campaign-list 
                  :campaigns="pushCampaigns()" 
                  @selectedCampaign="changeCampaign" 
                  :campaign_type="openTab"
                />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-campaign :selectedCampaign="campaign" :campaign_type="openTab" />
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== campaignList[1], 'block': openTab === campaignList[1]}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12">
                <card-campaign-list 
                  :campaigns="emailCampaigns()" 
                  @selectedCampaign="changeCampaign" 
                  :campaign_type="openTab"
                />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-campaign :selectedCampaign="campaign" :campaign_type="openTab" />
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== campaignList[2], 'block': openTab === campaignList[2]}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12">
                <card-campaign-list 
                  :campaigns="smsCampaigns()" 
                  @selectedCampaign="changeCampaign" 
                  :campaign_type="openTab"
                />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-campaign :selectedCampaign="campaign" :campaign_type="openTab" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardCampaign from "@/components/Cards/CardCampaign.vue";
// import CardProfile from "@/components/Cards/CardProfile.vue";
import CardCampaignList from "@/components/Cards/CardCampaignList.vue";
import ProductPackage from "@/components/Cards/ProductPackage.vue";
import { mapActions, mapGetters } from 'vuex';
import { campaignList } from "@/utils"

export default {
  components: {
    CardCampaign,
    CardCampaignList,
    ProductPackage
  },
  data() {
    return {
      campaign: {},
      openTab: "push"
    }
  },
  mounted() {
    this.getCampaigns({category: campaignList[0]});
    this.getCampaigns({category: campaignList[1]});
    this.getCampaigns({category: campaignList[2]});
  },
  created() {
    this.campaignList = campaignList;
  },
  methods: {
    ...mapActions(["getCampaigns"]),
    ...mapGetters([
      "emailCampaigns", 
      "smsCampaigns", 
      "pushCampaigns"
    ]),
    changeCampaign(campaign) {
      this.campaign = campaign
    },
    toggleTabs: function(tabNumber) {
      this.openTab = tabNumber
    },
  }
};
</script>
