<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0" v-if="has('user.all.view')">
            <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              <i class="pi pi-user-plus text-sm" style="font-size: 10px;"></i> Admins
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0" v-if="has('roles.index')">
            <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
              <i class="pi pi-key text-sm" style="font-size: 10px;"></i> Roles
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0" v-if="has('permission.view')">
            <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-orange-500': openTab === 3}">
              <i class="pi pi-folder-open text-sm" style="font-size: 10px;"></i> Permissions
            </a>
          </li>
          <li 
            class="-mb-px mr-2 last:mr-0" 
            v-if="has('business.index')"
            >
            <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 4, 'text-white bg-orange-500': openTab === 4}">
              <i class="pi pi-building-columns text-sm" style="font-size: 10px;"></i> Businesses
            </a>
          </li>
          <li class="-mb-px mr-2" v-if="has('questions.index')">
            <a class="text-xs px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(5)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 5, 'text-white bg-orange-500': openTab === 5}">
              <i class="pi pi-question text-sm" style="font-size: 10px;"></i> Questions
            </a>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12 px-4">
                <card-admin-list :selectedRole="role" :users="[]" ></card-admin-list>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-admin :roles="roles()" @selectedRole="changeRole" ></card-admin>
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12 px-4">
                <card-role-list :selectedRole="role" :permissions="permissions()" ></card-role-list>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-role :roles="roles()" @selectedRole="changeRole" ></card-role>
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}" class="flex flex-wrap">
              <div class="w-full px-4">
                <card-permission-list :permissions="permissions()" ></card-permission-list>
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}" class="flex flex-wrap">
              <div class="w-full px-4" :class="{'lg:w-4/12': Object.values(activeBusiness).length > 0}">
                <card-business-list @activeBusinessSelected="selectActiveBusiness" />
              </div>
              <div class="w-full lg:w-8/12 px-4" v-if="Object.values(activeBusiness).length > 0">
                <card-business :activeBusiness="activeBusiness" @close="() => activeBusiness = {}" />
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 5, 'block': openTab === 5}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12 px-4">
                <card-questions :selectedQuestion="question" />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-question-list :questions="questions()" @selectedQuestion="changeQuestion" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardQuestions from "@/components/Cards/CardQuestions.vue";
import CardRole from "@/components/Cards/CardRole.vue";
import CardRoleList from "@/components/Cards/CardRoleList.vue";
import CardPermissionList from "@/components/Cards/CardPermissionList.vue"
import CardAdmin from "@/components/Cards/CardAdmin.vue";
import CardAdminList from "@/components/Cards/CardAdminList.vue";
import CardBusinessList from "@/components/Cards/CardBusinessList.vue";
import CardBusiness from "@/components/Cards/CardBusiness.vue";
import CardQuestionList from "@/components/Cards/CardQuestionList.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CardQuestions,
    CardQuestionList,
    CardRole,
    CardRoleList,
    CardAdmin,
    CardAdminList,
    CardPermissionList,
    CardBusinessList,
    CardBusiness
  },
  data() {
    return {
      question: {},
      role: {},
      activeBusiness: {},
      openTab: 1
    }
  },
  mounted() {
    this.getQuestions();
    // this.selectAnwser();
    this.getRoles();
    this.getPermissions();
    this.getBusinesses();
  },
  methods: {
    ...mapActions(["getQuestions", 
    // "selectAnwser", 
    "getRoles", "getPermissions", "getBusinesses"]),
    ...mapGetters(["questions", "roles", "permissions"]),
    changeQuestion(question) {
      this.question = question
    },
    changeRole(role) {
      this.role = role
    },
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
    selectActiveBusiness: function(activeBusiness) {
      this.activeBusiness = activeBusiness
    }
  }
};
</script>
