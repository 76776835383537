import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
// import store from './store'

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import 'primeicons/primeicons.css'

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Plans from "@/views/admin/Plans.vue";
import Insight from "@/views/admin/Insight.vue";
import Transaction from "@/views/admin/Transactions.vue";
import ProviderLog from "@/views/admin/ProviderLogs.vue";
import Commission from "@/views/admin/Commission.vue";
import User from "@/views/admin/Users.vue";
import Rates from "@/views/admin/Rates.vue";
import Coins from "@/views/admin/Coins.vue";
import Maps from "@/views/admin/Maps.vue";
import Gateways from "@/views/admin/Gateways.vue";
import VAS from "@/views/admin/VAS.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import VerifyEmail from "@/views/auth/VerifyEmail.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
// import Index from "@/views/Index.vue";

import store from '@/store';
import pipeline from './middleware/pipeline.middleware';
import guest from './middleware/guest.middleware';
import auth from './middleware/auth.middleware';
import Campaign from "./views/admin/Campaign.vue";
// import credible from './middleware/credible.middleware';
// import verified from './middleware/verified.middleware';
// import question from './middleware/question.middleware';

// routes

const routes = [
    {
        name: "admin",
        path: "/admin",
        redirect: "/admin/dashboard",
        component: Admin,
        children: [{
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/admin/plans",
                component: Plans,
            },
            {
                path: "/admin/vas",
                component: VAS,
            },
            {
                path: "/admin/insight",
                component: Insight,
            },
            {
                path: "/admin/campaign",
                component: Campaign,
            },
            {
                path: "/admin/settings",
                component: Settings,
            },
            {
                path: "/admin/transactions",
                component: Transaction,
            },
            {
                path: "/admin/provider-log",
                component: ProviderLog,
            },
            {
                path: "/admin/gateway",
                component: Gateways,
            },
            {
                path: "/admin/commissions",
                component: Commission,
            },
            {
                path: "/admin/users",
                component: User,
            },
            {
                path: "/admin/rates",
                component: Rates,
            },
            {
                path: "/admin/coins",
                component: Coins,
            },
            {
                path: "/admin/maps",
                component: Maps,
            },
        ],
        meta: {
            middleware: [
                auth,
                // credible,
                // verified
            ]
        }
    },
    {
        name: "auth",
        path: "/auth",
        redirect: "/auth/login",
        component: Auth,
        children: [{
                path: "/auth/login",
                component: Login,
            },
            {
                path: "/auth/register",
                component: Register,
            },
            {
                path: "/auth/verify",
                component: VerifyEmail,
            },
            {
                path: "/auth/forgot-password",
                component: ForgotPassword,
            },
            {
                path: "/auth/reset-password",
                component: ResetPassword,
            },
        ],
        meta: {
            middleware: [
                guest
            ]
        }
    },
    {
        name: "profile",
        path: "/profile",
        component: Profile,
        meta: {
            middleware: [
                auth,
                // credible,
                // verified
            ]
        }
    },
    {
        path: "/",
        component: Landing,
    },
    { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {

    const middleware = to.meta.middleware

    if (!middleware || middleware.length === 0) {
        return next()
    }

    const context = {
        to,
        from,
        next,
        store
    };

    return middleware[0]({
        ...context,
        next: pipeline(context, middleware, 1)
    })
})

const app = createApp(App)

app.config.globalProperties = {
    ...app.config.globalProperties,
    is: (role) => {
        // return !!role;
        const roles = (store.getters.auth.roles || []).map( r => r.name)
        return roles.includes(role);
    },
    has: (permission) => {
        // return !!permission;
        let permissions = [];
        (store.getters.auth.roles || []).forEach( r => {
            permissions = [
                ...permissions,
                ...r.permissions.map(p => p.name)
            ]
        })
        return permissions.includes(permission);
    }
}

app.use(router)
    .use(store)
    .mount("#app");