<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="bg-white mb-0 px-4 py-2">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-lg">
          <i class="pi pi-sparkles"></i> {{ plan.id ? 'Update' : 'Create' }} Plan 
          <small class="text-blueGray-400">{{ Object.keys(stages).indexOf(stage) + 1 }}/{{ Object.keys(stages).length }}</small>
        </h6>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 rounded text-xs px-4 py-2 shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button" 
          @click="plan = {}"
          v-if="has('plans.store')"
        >
          <i class="pi pi-file-plus"></i> Create New Plan
        </button>
      </div>
    </div>
    <div class="flex-auto lg:px-6 py-10 pt-0">
      <!-- <vue-step-progress-indicator 
        :steps="Object.values(stages)" 
        :active-step="Object.keys(stages).indexOf(stage)" 
        :is-reactive="true"
      /> -->
      
      <form>
        <transition name="slide-fade">
          <div v-if="stage === stages.INFO">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 px-4">
              <i class="pi pi-info-circle"></i> Plan Information
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.name.$model"
                    placeholder="Growth Plan"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.name.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Tenure 
                  </label>
                  <input
                    type="number"
                    class="border-0 px-3 py-3 rounded placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.duration.$model"
                    placeholder="3"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.duration.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Tenure Unit
                  </label>
                  <select v-model="v$.plan.duration_unit.$model" class="border-0 rounded px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                    <option v-for="(type, i) in ['month', 'hour', 'day', 'week', 'year']" :key="i" :selected="type === plan.duration_unit">{{ type }}</option>
                  </select>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.duration_unit.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Grace period
                  </label>
                  <input
                    type="number"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.wait.$model"
                    placeholder="2"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.wait.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Grace Period Unit
                  </label>
                  <select 
                    v-model="v$.plan.wait_unit.$model" 
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    placeholder="day"
                  >
                    <option v-for="(type, i) in ['month', 'hour', 'day', 'week', 'year']" :key="i" :selected="type === plan.wait_unit">{{ type }}</option>
                  </select>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.wait_unit.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Plan Type
                  </label>
                  <select v-model="v$.plan.type.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150">
                    <option v-for="(type, i) in getPlanList" :key="i" :selected="type === plan.type">{{ type }}</option>
                  </select>

                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.type.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                  Reference
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.reference.$model"
                    placeholder="REF-1234#"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.reference.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-3 border-b-1 border-blueGray-300" />
            <div class="flex px-4 mt-3 justify-end">
              <button
                class="text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button"
                @click="stage=stages.SETTINGS" 
                :disable="canMoveToStep2"
                :class="`${ canMoveToStep2 ? 'bg-emerald-500 text-white active:bg-emerald-600' : 'bg-blueGray-100 text-blueGray-700 active:bg-blueGray-300'}`"
              >
                Next <i class="pi pi-arrow-right text-xs" style="font-size: 10px;"></i>
              </button>
            </div>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="stage === stages.SETTINGS">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 px-4">
              <i class="pi pi-cog"></i> Settings
            </h6>
            <div class="flex flex-wrap text-emerald-600">
              <div class="w-full px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                  opt out penalty (%)
                  </label>
                  <input
                    type="number"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.opt_out_penalty.$model"
                  />

                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.opt_out_penalty.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 py-1">
                <div class="relative w-full mb-3 text-xs">       
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                  Visibility
                  </label>
                  <label class="inline-flex items-center cursor-pointer">
                    <input id="opt-out" type="checkbox" v-model="v$.plan.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                    <span class="ml-2 text-xs text-emerald-600 mr-2"> Make Active? </span>
                    <i v-if="plan.is_active" class="fas fa-eye mr-1 text-sm text-emerald-600"></i>
                    <i v-else class="fas fa-eye-slash mr-1 text-sm text-emerald-600"></i>  
                  </label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.is_active.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 py-1">
                <div class="relative w-full mb-3">
                  
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                  flexibilty
                  </label>
                  <label class="inline-flex items-center cursor-pointer">
                    <input id="opt-out" type="checkbox" v-model="v$.plan.is_flexible.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                      <span class="ml-2 text-xs mr-2 text-emerald-600"> Can Opt Out? </span>
                      <i v-if="plan.is_flexible" class="fas fa-square mr-1 text-sm text-emerald-600"></i>
                      <i v-else class="fas fa-play mr-1 text-sm text-emerald-600"></i>
                    </label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.is_flexible.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full py-1">
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                    Group Plan
                    </label>
                    <label class="inline-flex items-center cursor-pointer">
                      <input id="opt-out" type="checkbox" v-model="v$.plan.is_group.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                        <span class="ml-2 text-xs mr-2 text-emerald-600"> Make Group Plan? </span>
                        <i class="fas fa-object-group mr-1 text-sm text-emerald-600"></i>  
                      </label>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.is_group.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4"  v-if="plan.is_group">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      Strategy
                    </label>
                    <select v-model="v$.plan.strategy.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150 rounded">
                      <option v-for="(strategy, i) in ['simultaneous', 'linear']" :key="i" :selected="strategy === plan.strategy">{{ strategy }}</option>
                    </select>

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.strategy.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full py-1">
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                    Balance Requirement
                    </label>
                    <label class="inline-flex items-center cursor-pointer">
                      <input id="opt-out" type="checkbox" v-model="v$.balance_requirement.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                        <span class="ml-2 text-xs mr-2 text-emerald-600"> Requires Balance? </span>
                        <i class="fas fa-credit-card mr-1 text-sm text-emerald-600"></i> 
                      </label>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.balance_requirement.is_active.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 flex" v-if="balance_requirement.is_active">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      Wallet Balance
                    </label>
                    <input
                      type="number"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                      v-model="v$.balance_requirement.settings.balance_reserve.$model"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.balance_requirement.settings.balance_reserve.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 flex" v-if="balance_requirement.is_active">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      Wallet Balance Unit
                    </label>
                    <select v-model="v$.balance_requirement.settings.balance_reserve_unit.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150">
                      <option v-for='(cur, i) in ["FLAT", "PERCENTAGE"]' :key="i" :selected="cur === balance_requirement.settings.balance_reserve_unit">{{ cur }}</option>
                    </select>

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.balance_requirement.settings.balance_reserve_unit.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full py-1">
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                    Savings Requirement
                    </label>
                    <label class="inline-flex items-center cursor-pointer">
                      <input id="opt-out" type="checkbox" v-model="v$.savings_requirement.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                        <span class="ml-2 text-xs mr-2 text-emerald-600"> Requires Savings? </span>
                        <i class="fas fa-folder mr-1 text-sm text-emerald-600"></i> 
                      </label>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savings_requirement.is_active.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 flex" v-if="savings_requirement.is_active">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      Savings Balance
                    </label>
                    <input
                      type="number"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                      v-model="v$.savings_requirement.settings.savings_reserve.$model"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savings_requirement.settings.savings_reserve.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 flex" v-if="savings_requirement.is_active">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      Savings Balance Unit
                    </label>
                    <select v-model="v$.savings_requirement.settings.savings_reserve_unit.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150">
                      <option v-for='(cur, i) in ["FLAT", "PERCENTAGE"]' :key="i" :selected="cur === savings_requirement.settings.savings_reserve_unit">{{ cur }}</option>
                    </select>

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savings_requirement.settings.savings_reserve_unit.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full py-1">
                <div class="w-full lg:w-4/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                    Guarantor Requirement
                    </label>
                    <label class="inline-flex items-center cursor-pointer">
                      <input id="opt-out" type="checkbox" v-model="v$.guarantor.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                        <span class="ml-2 text-xs mr-2 text-emerald-600"> Requires Guarantor? </span>
                        <i class="fas fa-users mr-1 text-sm text-emerald-600"></i> 
                      </label>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.guarantor.is_active.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 flex" v-if="guarantor.is_active">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      How many?
                    </label>
                    <input
                      type="number"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                      v-model="v$.guarantor.settings.count.$model"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.guarantor.settings.count.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-4/12 px-4 flex" v-if="guarantor.is_active">
                  <div class="relative w-full mb-3">
                    <label
                      class="block text-blueGray-600 text-xs mb-2"
                      htmlFor="grid-password"
                    >
                      Combine Balance (%)
                    </label>
                    <input
                      type="number"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                      v-model="v$.guarantor.settings.combine_amount.$model"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.guarantor.settings.combine_amount.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-3 border-b-1 border-blueGray-300" />
            <div class="flex px-4 mt-3 justify-end">
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.INFO"
                :disable="canMoveToStep3"
                :class="`${ canMoveToStep3 ? 'bg-emerald-500 text-white active:bg-emerald-600' : 'bg-blueGray-100 text-blueGray-700 active:bg-blueGray-300'}`"
              >
                <i class="pi pi-arrow-left text-xs" style="font-size: 10px;"></i> Prev
              </button>
              
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.COSTING"
              >
                Next <i class="pi pi-arrow-right text-xs" style="font-size: 10px;"></i>
              </button>
            </div>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="stage === stages.COSTING">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 px-4">
              <i class="pi pi-database"></i> Costing
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full lg:w-6/12 mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Currency
                  </label>
                  <select v-model="v$.plan.base_currency.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150">
                    <option v-for='(cur, i) in ["NGN", "USD"]' :key="i" :selected="cur === plan.base_currency">{{ cur }}</option>
                  </select>

                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.base_currency.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Min Amount
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.min_amount.$model"
                    placeholder="50,000"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.min_amount.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Max Amount
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.max_amount.$model"
                    placeholder="500,000"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.max_amount.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-3 mb-3 border-b-1 border-blueGray-300"/>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {{ plan.type && ['crypto', 'stock', 'forex'].includes(plan.type) ? "Stop Loss" : "Required Point" }}
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.stop_loss.$model"
                    placeholder="0"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.stop_loss.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    {{ plan.type && ['crypto', 'stock', 'forex'].includes(plan.type) ? "Take Profit" : "Interest Rate (%)" }}
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.take_profit.$model"
                    placeholder="10"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.take_profit.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                  {{ plan.type && ['crypto', 'stock', 'forex'].includes(plan.type) ? "Trail Take Profit" : "Late fee(%)" }}
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.trail_take_profit.$model"
                    placeholder="5"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.trail_take_profit.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                  Request Fees
                  </label>
                  <input
                    type="number"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                    v-model="v$.plan.request_fee.$model"
                    placeholder="200"
                  />
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.request_fee.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="flex px-4 mt-3 justify-end">
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.SETTINGS"
              >
                <i class="pi pi-arrow-left text-xs" style="font-size: 10px;"></i> Prev
              </button>
              
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.DETAILS"
                :disable="canMoveToStep4"
                :class="`${ canMoveToStep4 ? 'bg-emerald-500 text-white active:bg-emerald-600' : 'bg-blueGray-100 text-blueGray-700 active:bg-blueGray-300'}`"
              >
                Next <i class="pi pi-arrow-right text-xs" style="font-size: 10px;"></i>
              </button>
            </div>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="stage === stages.DETAILS">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold px-4">
              <i class="pi pi-info-circle"></i> Details
            </h6>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  Tags <span class="lowercase text-xs text-gray-500">* separated by comma (,)</span>
                </label>
                <input
                  type="text"
                  id="risk-type"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                  v-model="v$.plan.risk_type.$model"
                />
                <!-- <input
                  type="text"
                  id="risk-type"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                  :value="plan.risk_type ? JSON.parse(plan.risk_type).join(','): ''"
                  v-bind:input="v$.plan.risk_type.$model"
                /> -->
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Description
                  </label>
                  <textarea
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    rows="4"
                    v-model="v$.plan.description.$model"
                    placeholder="Describe this plan here"
                  ></textarea
                  >
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.description.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    How to buy
                  </label>
                  <textarea
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150"
                    rows="4"
                    v-model="v$.plan.how_to_buy.$model"
                    placeholder="Describe how to buy plan here"
                  ></textarea
                  >
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.how_to_buy.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="flex px-4 mt-3 justify-end">
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.COSTING"
              >
                <i class="pi pi-arrow-left text-xs" style="font-size: 10px;"></i> Prev
              </button>
              
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.KYC"
                :disable="canMoveToStep5"
                :class="`${ canMoveToStep5 ? 'bg-emerald-500 text-white active:bg-emerald-600' : 'bg-blueGray-100 text-blueGray-700 active:bg-blueGray-300'}`"
              >
                Next <i class="pi pi-arrow-right text-xs" style="font-size: 10px;"></i>
              </button>
            </div>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="stage === stages.KYC">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 px-4">
              <i class="pi pi-verified"></i> KYC Requirements
            </h6>
            <div class="flex flex-wrap text-emerald-600">
              <div class="w-full px-4 py-1">
                <div class="relative w-full mb-3 text-xs">       
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Finance
                  </label>
                  <label class="inline-flex items-center cursor-pointer">
                    <input id="opt-out" type="checkbox" v-model="v$.finance.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear rounded transition-all duration-150">
                    <span class="ml-2 text-xs text-emerald-600"> require bank statement? </span>
                  </label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.finance.is_active.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 py-1">
                <div class="relative w-full mb-3">
                  
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Location
                  </label>
                  <label class="inline-flex items-center cursor-pointer">
                    <input id="opt-out" type="checkbox" v-model="v$.location.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 rounded ease-linear transition-all duration-150 rounded">
                      <span class="ml-2 text-xs mr-2 text-emerald-600"> eg. electricity bill, waste disposal bill, rent receipt </span>
                      <i class="fas fa-pin mr-1 text-sm text-emerald-600"></i>
                    </label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.location.is_active.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full py-1">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                    Identification
                    </label>
                    <label class="inline-flex items-center cursor-pointer">
                      <input id="opt-out" type="checkbox" v-model="v$.identification.is_active.$model" class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                        <span class="ml-2 text-xs mr-2 text-emerald-600"> eg. International passport, drivers license, voter’s card, national identity card </span>
                        <i class="fas fa-object-group mr-1 text-sm text-emerald-600"></i>  
                      </label>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.identification.is_active.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap w-full py-1">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                    Employment
                    </label>
                    <label class="inline-flex items-center cursor-pointer">
                      <input id="opt-out" type="checkbox" v-model="v$.employment.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 rounded ease-linear transition-all duration-150">
                        <span class="ml-2 text-xs mr-2 text-emerald-600"> company id card, letter of employment, payslip </span>
                        <i class="fas fa-credit-card mr-1 text-sm text-emerald-600"></i> 
                      </label>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.employment.is_active.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4 py-1">
                <div class="relative w-full mb-3">
                  
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                  Business Document
                  </label>
                  <label class="inline-flex items-center cursor-pointer">
                    <input id="opt-out" type="checkbox" v-model="v$.business_document.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear rounded transition-all duration-150">
                      <span class="ml-2 text-xs mr-2 text-emerald-600"> CAC document </span>
                      <i class="fas fa-folder mr-1 text-sm text-emerald-600"></i> 
                    </label>
                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business_document.is_active.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4 flex" v-if="business_document.is_active">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Select Document
                  </label>
                  <select multiple v-model="v$.business_document.settings.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none rounded w-full ease-linear transition-all duration-150">
                    <option v-for='(cur, i) in ["CAC1", "CAC2", "CAC7", "certificate of incorporation"]' :key="i" :selected="cur === business_document.settings">{{ cur }}</option>
                  </select>

                  <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.business_document.settings.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="flex px-4 mt-3 justify-end">
              <button
                class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="stage=stages.COSTING"
              >
                <i class="pi pi-arrow-left text-xs" style="font-size: 10px;"></i> Prev
              </button>
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="updateExistingPlan" 
                :disabled="loaders().updatePlan"
                v-if="has('plans.store')"
              >
              <i class="pi pi-save text-xs" style="font-size: 10px;"></i> Save
              </button>
            </div>
          </div>
        </transition>

        <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.plan.$errors" :key="index">
          <div class="error-msg">
            <small>{{ error.$message }}</small>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal, numeric, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
// import ProductPackage from "@/components/Cards/ProductPackage.vue";
// import VueStepProgressIndicator from "vue-step-progress-indicator";
// import { createToaster } from "@meforma/vue-toaster";
// const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedPlan", "deletedPlan" ],
  components: {
    // ProductPackage
    // VueStepProgressIndicator,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    canMoveToStep2() {
      return !this.v$.plan.name.$invalid && !this.v$.plan.duration.$invalid && !this.v$.plan.duration_unit.$invalid && !this.v$.plan.wait.$invalid && !this.v$.plan.reference.$invalid
    },
    canMoveToStep3() {
      return !this.v$.plan.opt_out_penalty.$invalid && !this.v$.plan.is_active.$invalid && !this.v$.plan.is_flexible.$invalid && !this.v$.plan.is_group.$invalid && !this.v$.plan.strategy.$invalid
    },
    canMoveToStep4() {
      return !this.v$.plan.base_currency.$invalid && !this.v$.plan.min_amount.$invalid && !this.v$.plan.max_amount.$invalid && !this.v$.plan.stop_loss.$invalid && !this.v$.plan.take_profit.$invalid && !this.v$.plan.trail_take_profit.$invalid && !this.v$.plan.request_fee.$invalid
    }, 
    canMoveToStep5() {
      return !this.v$.plan.risk_type.$invalid && !this.v$.plan.description.$invalid && !this.v$.plan.how_to_buy.$invalid
    },
    ...mapGetters({
      businessSubscription: "subscription"
    }),
    getPlanList() {
      const products = (this.businessSubscription || []).map( b => b.product.toLowerCase()) || [] // 'savings', 'loan', 'investment'
      return ['estate', 'crypto', 'stock', 'forex', ...products]
    }
  },
  watch: {
    // whenever question changes, this function will run
    selectedPlan(plan) {
      // console.log(oldPlan, newPlan)
      this.stage = this.stages.INFO;
      this.plan = plan;
      this.plan.check_balance = !!plan.check_balance;
      this.plan.check_savings = !!plan.check_savings;
      this.plan.is_active = !!plan.is_active;
      this.plan.is_flexible = !!plan.is_flexible;
      this.plan.is_group = !!plan.is_group;
      this.plan.balance_reserve = `${plan.balance_reserve ? plan.balance_reserve : 0}`
      this.plan.savings_reserve = `${plan.savings_reserve ? plan.savings_reserve : 0}`
      this.updateConfigs(this.plan.configs);
    }
  },
  data() {
    return {
      plan: {},
      //plan_id, type, settings, order, is_active, is_required, continue_on_failure, pre_plan
      guarantor: {
        id: null,
        type: "guarantor",
        is_active: false,
        pre_plan: true,
        settings: {
          count: 0,
          combine_amount: 0
        }
      },
      balance_requirement: {
        id: null,
        type: "balance_requirement",
        is_active: false,
        pre_plan: true,
        settings: {
          balance_reserve: 0,
          balance_reserve_unit: "PERCENTAGE"
        }
      },
      savings_requirement: {
        id: null,
        type: "savings_requirement",
        is_active: false,
        pre_plan: true,
        settings: {
          savings_reserve: 0,
          savings_reserve_unit: "PERCENTAGE"
        }
      },
      finance: {
        id: null,
        type: "finance",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      location: {
        id: null,
        type: "location",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      identification: {
        id: null,
        type: "identification",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      employment: {
        id: null,
        type: "employment",
        is_active: false,
        pre_plan: true,
        settings: {}
      },
      business_document: {
        id: null,
        type: "business_document",
        is_active: false,
        pre_plan: true,
        settings: []
      },
      stages: {
        INFO: "INFO",
        SETTINGS: "SETTINGS",
        COSTING: "COSTING",
        LIMIT: "LIMIT",
        DETAILS: "DETAILS",
        KYC: "KYC"
      },
      stage: "INFO"
    }
  },
  mounted() {},
  validations() {
    return {
      plan: {
        name: { required },
        base_currency: { required },
        type: { required },
        description: { required },
        min_amount: { required, decimal, min: minLength(1) },
        max_amount: { required, decimal, min: minLength(1) },
        take_profit: { required },
        trail_take_profit: { required },
        stop_loss: { required, numeric },
        risk_type: { required, numeric },
        is_active: { required },
        is_group: {},
        duration: { required, numeric, min: minLength(1) },
        duration_unit: { required },
        wait: { numeric, min: minLength(1) },
        wait_unit: { },
        how_to_buy: { },
        reference: { required },
        is_flexible: { },
        opt_out_penalty: { numeric },
        check_balance: {},
        balance_reserve_unit: {},
        balance_reserve: {numeric},
        check_savings: {},
        savings_reserve: {numeric},
        savings_reserve_unit: {},
        strategy: {},
        request_fee: { numeric }
      },
      guarantor: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {
          count: { required },
          combine_amount: { required }
        }
      },
      balance_requirement: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {
          balance_reserve: { required },
          balance_reserve_unit: { required }
        }
      },
      savings_requirement: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {
          savings_reserve: { required },
          savings_reserve_unit: { required }
        }
      },
      finance: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      location: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      identification: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      employment: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: {}
      },
      business_document: {
        id: {},
        type: { required },
        is_active: { required },
        pre_plan: { required },
        settings: []
      }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([
      "planConfigBulk",
      "updatePlan", 
      "addPlan",
      "subscription"
    ]),
    updateConfigs(configs) {
      for(const config of configs) {
        config.is_active = !!config.is_active;
        config.is_required = !!config.is_required;
        config.continue_on_failure = !!config.continue_on_failure;
        config.pre_plan = !!config.pre_plan;
        config.settings = typeof config.settings === "string" ? JSON.parse(config.settings) : config.settings
        this[config.type] = config
      }
    },
    updateExistingPlan() {
      const data = this.plan;
      // const risk_type = document.querySelector("#risk-type").value;
      data.risk_type = JSON.stringify(data.risk_type?.split(","));
      data.id ? 
      this.updatePlan(data).then( () => {
        this.persistConfigs(data.id)
      }) : 
      this.addPlan(data).then( (res) => {
        this.plan = {}
        const id = res.data ? res.data.id : res.id
        this.persistConfigs(id)
      })
    },
    persistConfigs(id) {
      let configs = [
        this.guarantor,
        this.balance_requirement,
        this.savings_requirement,
        this.finance,
        this.location,
        this.identification,
        this.employment,
        this.business_document
      ];
      configs = configs.map(v => ({...v, plan_id: id}))
      const self = this
      this.planConfigBulk(configs).then(r => self.updateConfigs(r))
    }
  }
}
</script>