<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative px-4">
          <h3
            class="text-lg text-blueGray-700"
          >
          <i class="pi mx-2 text-sm pi-users"></i> User
          </h3>
        </div>

        <div class="flex mb-3">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            :disabled="loading"
            @click="toggleModal('filter', true)"
          >
          <i class="pi pi-filter text-xs" style="font-size: 10px;"></i> Filter
          </button>
          
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            :disabled="loading"
            @click="get_csv=true;fetchNewUserPage()"
          >
          <i class="pi pi-download text-xs" style="font-size: 10px;"></i> Download
          </button>
        </div>

      </div>
    </div>

    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Email
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Phone
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emeblockUserrald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Verified
            </th>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Naira Balance <br />
              <small>
                (Ledger/Balance)
              </small>
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              USD Balance <br/>
              <small>
                (Ledger/Balance)
              </small>
            </th>
            <!-- <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Registration
            </th> -->
            <th
              class="px-6 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            ></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!loaders().getAllUsers">
            <tr v-for="(user, i) in users().data" :key="i">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center cursor-pointer"
                @click="(e) => {
                  setUser(user); 
                  toggleModal('userInformation', true)
                }"
              >
                <img
                  v-if="user.profile?.image && user.profile?.image.length > 5"
                  :src="$_get_image_url(user.profile?.image)"
                  class="h-12 w-12 bg-white rounded-full border"
                  alt="..."
                />
                <div v-else class="h-12 w-12 bg-white rounded-full border flex justify-center items-center">
                  {{ getInitials(user.name) }}
                </div>
                <span
                  class="ml-3 font-bold"
                  :class="[
                    color === 'light' ? 'text-blueGray-600' : 'text-white',
                    user.is_blocked ? 'line-through italic' : ''
                  ]"
                >
                  {{ user.name }}
                </span>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                @click="clickToCopy(user.email)"
              >
              <span :class="[
                user.is_blocked ? 'line-through italic' : ''
              ]">
                {{ user.email }} <i v-if="user.email" class="pi pi-clone text-xs" ></i>
              </span>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                @click="clickToCopy(user.profile?.phone)"
              >
                {{ user.profile?.phone }} <i v-if="user.profile?.phone" class="pi pi-clone text-xs" ></i>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i :class="`fas fa-circle ${user.email_verified_at ? 'text-emerald-600' :  'text-orange-500 '}  mr-2`"></i> {{ user.email_verified_at ? 'yes' : 'no' }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">
                    {{ formatter(user.naira_wallet?.base_currency).format(user.naira_wallet?.ledger_balance || 0) }} / 
                    {{ formatter(user.naira_wallet?.base_currency).format(user.naira_wallet?.balance || 0) }}
                  </span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">
                    {{ user.usd_wallet?.base_currency }}
                    {{ user.usd_wallet?.ledger_balance }} / 
                    {{ user.usd_wallet?.base_currency }}
                    {{ user.usd_wallet?.balance }}
                  </span>
                </div>
              </td>
              <!-- <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">
                    {{ moment(user.created_at).format("DD MMM YY hh:mm") }}
                  </span>
                </div>
              </td> -->
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
                v-if="has('user.block.create') || has('user.unblock.create')"
              >
                <table-dropdown 
                  :user="user"
                  @view-info-click="(e) => {
                    setUser(user); 
                    toggleModal('userInformation', true)
                  }"
                  @debit-click="(e) => {setUser(user); toggleModal('debitUserWallet', true)}"
                />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6" rowspan="6">
                <div class="flex items-center justify-center w-full my-6">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <empty-list v-if="(!users().data || users().data.length === 0) && !loaders().getAllUsers"></empty-list>
    </div>

    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="users().current_page" :last_page="users().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>

    <div v-if="modals.userInformation" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              User Information
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('userInformation', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <recursive-table
            :data="user"
            :excludeAll="['id', 'is_password_auto_gen', 'usd_wallet', 'user_id']"
            :imagesAll="['logo', 'banner', 'image']"
            :telsAll="['phone']"
            :emailsAll="['email', 'support_email']"
            :datesAll="['created_at', 'updated_at', 'email_verified_at']"
            :copies="['code', 'phone']"
          />
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('userInformation', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.debitUserWallet" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Debit {{ user.name }} Wallet
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('debitUserWallet', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form>
              <div class="flex flex-wrap">
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Select Wallet Type
                    </label>
                    <select v-model="v$.debit.currency.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                      <option v-for="(currency, i) in ['NGN', 'USD']" :key="i" :value="currency">
                      {{ currency }} <small>({{ currency }})</small>
                      </option>
                    </select>
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.debit.currency.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="w-full px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                      v-model="v$.debit.amount.$model"
                    />
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.debit.amount.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                      rows="4"
                      v-model="v$.debit.description.$model"
                    ></textarea
                    >
                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.debit.description.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative w-full mb-3 px-4 mt-8">
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                  type="button" 
                  @click="debitUserWallet" 
                  :disabled="loaders().withdrawToAdmin || v$.debit.$invalid"
                  v-if="has('wallet.debit.ability')"
                >
                  Continue
                </button>
              </div>

            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('debitUserWallet', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.userInformation || modals.debitUserWallet" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
    <filter-modal 
      :selects="{
        email_verified: ['yes', 'no'], 
        phone_verified: ['yes', 'no'],
        referred: ['yes', 'no'], 
        blocked: ['yes', 'no'], 
        auto_gen: ['yes', 'no']
      }"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter" 
      @close="modals.filter=false" 
      title="user"
      @filter="filter"
      :loading="loaders().getAllUsers"
    ></filter-modal>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';
import TableDropdown from "@/components/Dropdowns/UserTableDropdown.vue";
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import RecursiveTable from "@/components/Tables/RecursiveTable.vue";
import FilterModal from "@/components/Modals/FilterModal.vue";
import bootstrap from "@/assets/img/bootstrap.jpg";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import { getInitials, formatter, clickToCopy } from "@/utils"
import { images } from '@/mixins'
import moment from "moment"
import EmptyList from "@/components/Cards/EmptyList.vue";

const toaster = createToaster({ /* options */ });

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  mixins: [images],
  data() {
    return {
      bootstrap,
      pageSize: 10,
      timer: null,
      params: {},
      loading: false,
      get_csv: false,
      pagination:{},
      user: {},
      modals: {},
      debit: {}
    };
  },
  components: {
    TableDropdown,
    CircularPagination,
    RecursiveTable,
    FilterModal,
    EmptyList
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  validations() {
    return {
      debit: {
        amount: { required, decimal },
        currency: { required },
        description: {}
      }
    }
  },
  methods: {
    ...mapActions(["getAllUsers", "logout", "withdrawToAdmin"]),
    ...mapGetters(["users", "loaders"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewUserPage();
    },
    filter(params) {
      this.params = params;
      this.fetchNewUserPage(params);
    },
    fetchNewUserPage(p = {}) {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        ...this.params,
        ...p
      }
      const self = this
      this.loading = true
      this.getAllUsers(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','users.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    setUser(user) {
      this.user = user;
    },
    toggleModal(modalName, isvisible) {
      // this.setStage("");
      this.modals[modalName] = isvisible
    },
    logoutUser: function() {
      this.logout().then(() => {
        const self = this;
        setTimeout( function(){
          self.$router.push('/auth');
        }, 900);
      }).catch( err => {
        console.log(err)
      })
    },
    async debitUserWallet() {
      if (!confirm(`Confirm you want to debit ${this.debit.currency} ${this.debit.amount} from ${this.user.name} wallet`) && this.$) {
        return;
      }
      const payload = {
        ...this.debit,
        user_id: this.user.id
      }
      try {
        await this.withdrawToAdmin(payload)
        this.debit.amount = 0;
        this.debit.description = "";
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.getAllUsers().then( r => {
      const { message } = r
      const self = this
      if ( message === "Unauthenticated." ) {
        self.logoutUser()
      }
    }).catch( e => {
      const { message } = e
      const self = this
      if ( message === "Unauthenticated." ) {
        self.logoutUser()
      }
    })
  },
  created() {
    this.moment = moment;
    this.getInitials = getInitials
    this.formatter = formatter
    this.clickToCopy = clickToCopy
  }
};
</script>
