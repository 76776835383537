import { AddSettings, GetSettings, UpdateSettings } from "@/services/settings";

export const getSettings = (context) => {
  context.commit("updateLoader", { fetchSettings: true });
  return new Promise((resolve, reject) => {
    GetSettings()
      .then(res => {
        context.commit("fetchSettings", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { fetchSettings: false });
      });
    });
};

export const addSettings = (context, data) => {
  context.commit("updateLoader", { addSettings: true });
  return new Promise((resolve, reject) => {
    AddSettings(data)
      .then(res => {
        context.commit("addSettings", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { addSettings: false });
      });
    });
};

export const updateSettings = (context, data) => {
  context.commit("updateLoader", { updateSettings: true });
  return new Promise((resolve, reject) => {
    UpdateSettings(data)
      .then(res => {
        context.commit("updateSettings", res.data.data);
        resolve(res.data.data);
      })
      .catch(err => {
        reject(err);
      })
      .finally(() => {
        // stop loaders
        context.commit("updateLoader", { updateSettings: false });
      });
    });
};

const SettingsAction = {
  getSettings,
  addSettings,
  updateSettings
};

export default SettingsAction;
