<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-4 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs"><i class="pi pi-bolt"></i>{{ fee.name }} | {{ fee.type }} fee</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
          <i class="pi pi-info-circle"></i> Fee Information
        </h6>
        <hr class="my-2 border-b-1 border-blueGray-300" />
        <div class="flex flex-wrap">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                type="text"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.fee.name.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Type
              </label>
              <select v-model="v$.fee.type.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(type, i) in ['COMMISSION', 'CONVENIENCE']" :key="i" :value="type">
                {{ type.toLowerCase() }}
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.type.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Role
              </label>
              <select v-model="v$.fee.role_id.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(role, i) in roles()" :key="i" :value="role.id">
                {{ role.name }}
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.role_id.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Transaction Type
              </label>
              <select v-model="v$.fee.transaction_type.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(type, i) in transactionList" :key="i" :value="type">
                {{ type.split("_").join(" ").toLowerCase() }}
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.transaction_type.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Amount
              </label>
              <input
                type="number"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.fee.amount.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Rate
              </label>
              <select v-model="v$.fee.rate.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(rate, i) in ['FLAT', 'PERCENTAGE']" :key="i" :value="rate">
                {{ rate.toLowerCase() }}
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.rate.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Frequency
              </label>
              <select v-model="v$.fee.frequency.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(frequency, i) in ['INSTANT', 'DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']" :key="i" :value="frequency">
                {{ frequency.toLowerCase() }}
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.frequency.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
              Authorization
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.fee.requires_confirmation.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                <span class="ml-2 text-xs text-emerald-600 mr-2"> Requires Authorization </span>
                <i v-if="fee.requires_confirmation" class="pi pi-lock mr-1 text-xs"></i>
                <i v-else class="pi pi-lock-open mr-1 text-xs"></i>  
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.requires_confirmation.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
              Visibility
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.fee.is_active.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                <span class="ml-2 text-xs text-emerald-600 mr-2"> Is Active</span>
                <i v-if="fee.is_active" class="fas fa-eye mr-1 text-xs"></i>
                <i v-else class="fas fa-eye-slash mr-1 text-xs"></i>  
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.is_active.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full px-1">
            <div class="relative w-full mb-3 text-xs">       
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
              Distribution
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.fee.is_shared.$model" class="form-checkbox border-0 text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded">
                <span class="ml-2 text-xs text-emerald-600 mr-2"> {{ fee.is_shared ? 'Shared' : 'Individual' }} </span>
                <i v-if="fee.is_shared" class="pi pi-users mr-1 text-xs"></i>
                <i v-else class="pi pi-user mr-1 text-xs"></i>  
              </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.fee.is_shared.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mb-3 px-1 mt-3">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="updateExistingPlan" 
            :disabled="loaders().updateFee || loaders().saveFee"
            v-if="has('fees.update') || has('fees.save')"
          >
          <i class="pi pi-refresh text-xs"></i> {{ fee.id ? 'Update' : 'Save' }}
          </button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal, numeric } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import { strings } from '@/mixins'
import { confirmAction, transactionList } from '@/utils'
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedFee" ],
  components: {
  },
  mixins: [strings],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedFee(fee) {
      this.fee = fee
      this.parseBoolean(fee)
    }
  },
  data() {
    return {
      transactionList,
      fee: this.selectedFee || {}
    }
  },
  validations() {
    return {
      fee: {
        type: { required },
        role_id: { required, numeric },
        transaction_type: { required },
        name: { required }, 
        rate: { required },
        amount: { required, decimal },
        frequency: { required }, 
        requires_confirmation: {},
        is_active: {},
        is_shared: {}
      }
    }
  },
  methods: {
    ...mapGetters(["loaders", "roles"]),
    ...mapActions([ 
      "saveFee", 
      "updateFee"
    ]),
    updateExistingPlan() {
      const data = this.fee;

      confirmAction({
        confirmText: `Are you sure you want to ${data.id ? 'update' : 'create'} this plan?`,
        successText: `The plan has been ${data.id ? 'updated' : 'created'}`,
        cancelText: `${data.id ? 'Updating' : 'Creation'} of plan aborted`
      }, () => {
        const q = data.id ? this.updateFee(data) : this.saveFee(data);
        q.then( () => {}).catch(() => {
          toaster.error('An error occored, please try again.')
        })
      });
    },
    parseBoolean(fee) {
      this.fee.requires_confirmation = !!fee.requires_confirmation
      this.fee.is_active = !!fee.is_active
      this.fee.is_shared = !!fee.is_shared
    },
  },
  mounted() {
    this.parseBoolean(this.selectedFee)
  }
}
</script>