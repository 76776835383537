<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700">
            <i class="pi pi-list-check"></i> Plan List
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Name
            </th>
            <th
              class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Amount
            </th>
            <th
              class="px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(plan, i) in plans" :key="i" class="cursor-pointer" @click="selectPlan(plan)">
            <th
              class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-2 px-4 text-left"
            >
              <i class="pi pi-arrow-right"></i> {{ plan.name }}
            </th>
            <td
              class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-2 px-4 text-xs"
            >
              {{ formatter(plan.base_currency).format(plan.min_amount) }} - {{ formatter(plan.base_currency).format(plan.max_amount) }}
            </td>
            <td
              class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-2 px-4"
              @click="removePlan(plan)"
              v-if="this.has('plans.destroy')"
            >
              <div class="flex items-center">
                <i class="fas fa-times mr-2 text-sm text-blueGray-300 hover:text-red-600"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatter } from '@/utils'

export default {
  props: ["plans"],
  created() {
    this.formatter = formatter
  },
  methods: {
    ...mapActions(["deletePlan"]),
    selectPlan(plan) {
      if( this.has('plans.show') || this.has('plans.update') || this.has('plans.store') ) {
        this.$emit('selectedPlan', plan)
      }
    },
    // deletePlan(plan) {
    //   this.$emit('deletedPlan', plan)
    // },
    removePlan(plan) {
      let person = prompt("Please type 'delete'");
      if (person === 'delete') {
        this.deletePlan(plan)
      }
    }
  }
}
</script>
