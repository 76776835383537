import {
    GetGatewayConfigs,
    AddGatewayConfig,
    UpdateGatewayConfig,
    DeleteGatewayConfig,
    ActivateGatewayConfig,
    DeactivateGatewayConfig
} from "@/services/config";

export const getGatewayConfigs = (context, data = {}) => {
    context.commit("updateLoader", { fetchGatewayConfigs: true });
    return new Promise((resolve, reject) => {
        GetGatewayConfigs(data)
            .then(res => {
                const result = res.data.data
                context.commit("fetchGatewayConfigs", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchGatewayConfigs: false });
            });
    });
}

export const addGatewayConfig = (context, data) => {
    context.commit("updateLoader", { addGatewayConfig: true });
    return new Promise((resolve, reject) => {
        AddGatewayConfig(data)
            .then(res => {
                const result = res.data.data
                context.commit("addGatewayConfig", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addGatewayConfig: false });
            });
    });
}

export const updateGatewayConfig = (context, data) => {
    context.commit("updateLoader", { updateGatewayConfig: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        UpdateGatewayConfig(id, data)
            .then(res => {
                const result = res.data.data
                context.commit("updateGatewayConfig", {result, data});
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateGatewayConfig: false });
            });
    });
}

export const deleteGatewayConfig = (context, {gateway, provider}) => {
    context.commit("updateLoader", { deleteGatewayConfig: true });
    return new Promise((resolve, reject) => {
        DeleteGatewayConfig(gateway, provider)
            .then(res => {
                const data = res.data.data
                context.commit("deleteGatewayConfig", {data, provider, gateway});
                resolve(data)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteGatewayConfig: false });
            });
    });
}

export const activateGatewayConfig = (context, {gateway, provider}) => {
    context.commit("updateLoader", { activateGatewayConfig: true });
    return new Promise((resolve, reject) => {
        ActivateGatewayConfig(gateway, provider)
            .then(res => {
                const data = res.data.data
                context.commit("activateGatewayConfig", {data, gateway, provider});
                resolve(data)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { activateGatewayConfig: false });
            });
    });
}

export const deactivateGatewayConfig = (context, {gateway, provider}) => {
    context.commit("updateLoader", { deactivateGatewayConfig: true });
    return new Promise((resolve, reject) => {
        DeactivateGatewayConfig(gateway, provider)
            .then(res => {
                console.log(res)
                const data = res.data.data
                context.commit("deactivateGatewayConfig", {data, provider, gateway});
                resolve(data)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deactivateGatewayConfig: false });
            });
    });
}


const GatewayConfigAction = {
    getGatewayConfigs,
    addGatewayConfig,
    updateGatewayConfig,
    deleteGatewayConfig,
    activateGatewayConfig,
    deactivateGatewayConfig
};

export default GatewayConfigAction;