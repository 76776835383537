<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-2 max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700">
            <i class="pi mx-2 text-sm pi-wave-pulse"></i> &nbsp;Rate List
          </h3>
        </div>
        <div
          class="relative w-full px-2 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            <i class="pi pi-filter text-sm" style="font-size: 10px;"></i> filter
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Currency
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Rate
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Buy Rate
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Sell Rate
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Active
            </th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="(currency, i) in currencies" :key="i" class="cursor-pointer" @click="selectRate(currency)">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              {{ currency.currency }}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ formatter("NGN").format(currency.rate) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ formatter("NGN").format(currency.buy_rate) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ formatter("NGN").format(currency.sell_rate) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ currency.is_active ? 'yes' : 'no' }}
            </td>
          </tr>
        </tbody>
      </table>
      <empty-list v-if="currencies.length === 0"></empty-list>
    </div>
  </div>
</template>

<script>
import { formatter } from "@/utils"
import EmptyList from "@/components/Cards/EmptyList.vue";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: ["currencies"],
  components: {
    EmptyList
  },
  methods: {
    selectRate(currency) {
      if (this.has('rates.show')) {
        this.$emit('selectedRate', currency)
      } else {
        toaster.show("You don't have the requisite permission")
      }
    }
  },
  created() {
    this.formatter = formatter
  }
}
</script>
