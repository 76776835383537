<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-4 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs font-bold">{{ userPlan.user?.name }} | {{ userPlan.plan?.name }} Plan</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
          <i class="pi pi-user"></i> User Information
        </h6>
        <div class="flex flex-wrap">
          <div class="relative w-full mb-3">
            {{ userPlan.user?.name }}
            <br/>
            <small @click="$_click_to_copy(userPlan.user?.email)">
              {{ userPlan.user?.email }} <i v-if="userPlan.user?.email" class="pi pi-clone text-xs" ></i>
            </small>
          </div>
        </div>

        <hr class="my-2 border-b-1 border-blueGray-300" />


        <div class="flex flex-wrap">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Amount
              </label>
              <input
                type="number" 
                disabled
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.userPlan.amount.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.userPlan.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Rate
              </label>
              <input
                type="email"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.userPlan.rate.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.userPlan.rate.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Status
              </label>
              <select v-model="v$.userPlan.status.$model" class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for="(status, i) in ['INITIATED', 'PENDING', 'FAILED', 'DECLINED', 'ACTIVE', 'TERMINATED', 'DUE', 'DEFAULT', 'COMPLETED', 'WITHDRAWN', 'APPROVED', 'BAD']" :key="i" :value="status">
                {{ status }} <small>({{ status }})</small>
                </option>
              </select>

              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.userPlan.status.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>

            </div>
          </div>

        </div>

        <div class="relative mb-3 px-1 mt-3">
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="terminate" 
            :disabled="loaders().terminatePlan"
            v-if="userPlan.type !== 'loan' && ['ACTIVE', 'PENDING', 'DUE'].includes(userPlan.status) && has('plan.terminate.ability')"
          >
            <i class="pi pi-ban text-xs"></i> Terminate
          </button>
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button" 
            @click="updateExistingPlan" 
            :disabled="loaders().updatePlanUser"
            v-if="has('plans.update')"
          >
          <i class="pi pi-refresh text-xs"></i> Update
          </button>
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="showInstallments=!showInstallments"
          >
            <i class="pi pi-list-check"></i> Installments
          </button>
        </div>
      </form>
      <hr class="my-4 border-b-1 border-blueGray-300" />
      <div v-if="showInstallments" class="block w-full overflow-x-auto">
        <template v-if="userPlan.savings?.length > 0">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
              Savings Plan
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="relative w-full mb-3">
                  <table class="items-center w-full bg-transparent border-collapse">
                    <thead class="thead-light">
                    </thead>
                    <tbody>
                      <tr v-for="(saving, i) in ( userPlan.savings )" :key="i" class="cursor-pointer">
                        <th
                          class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                        >
                          <div class="font-light">
                            {{ userPlan.currency }} {{ saving.amount_paid.toFixed(2) }} | {{ userPlan.currency }} {{ saving.amount.toFixed(2) }} <br/>
                            <small>{{ saving.due_at }}</small>
                          </div>
                          
                        </th>
                        <td
                          class="border-t-0 px-2 font-light align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                          {{ saving.status }}
                        </td>
                        <td
                          class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                        >
                        <button
                          class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          type="button" 
                          @click="nudgeSavings(saving)"
                        >
                          <i class="pi pi-bell text-xs" style="font-size: 10px;"></i> nudge 
                        </button>

                        <button
                          class="bg-red-500 text-white active:bg-red-600 text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          type="button" 
                          @click="handleUpdateSavings(saving)"
                          v-if="saving.status !== 'CANCELLED'"
                          :disabled="loaders().updateSavings"
                        >
                        <i class="pi pi-times-circle text-xs" style="font-size: 10px;"></i> cancel 
                        </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </template>
        <template v-else-if="userPlan.loans?.length > 0">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
            Loan Repayment Plan
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="relative w-full mb-3">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead class="thead-light">
                  </thead>
                  <tbody>
                    <tr v-for="(loan, i) in ( userPlan.loans )" :key="i" class="cursor-pointer">
                      <th
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                      >
                        <div class="font-light">
                          {{ userPlan.currency }} {{ Math.round(loan.amount_paid * 100)/100 }} | {{ userPlan.currency }} {{ loan.amount.toFixed(2) }} <br/>
                          <small>{{ loan.due_at }}</small>
                        </div>
                        
                      </th>
                      <td
                        class="border-t-0 font-light px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        {{ loan.status }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" 
                        @click="debitLoan(loan)"
                      >
                      <i class="pi pi-credit-card text-xs" style="font-size: 10px;"></i> Debit 
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="userPlan.investments?.length > 0">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
            Investment Payment Plan
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="relative w-full mb-3">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead class="thead-light">
                  </thead>
                  <tbody>
                    <tr v-for="(investment, i) in ( userPlan.investments )" :key="i" class="cursor-pointer">
                      <th
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                      >
                        <div class="font-light">
                          {{ userPlan.currency }} {{ investment.amount_paid.toFixed(2) }} <br/>
                          <small>{{ investment.due_at }}</small>
                        </div>
                        
                      </th>
                      <td
                        class="border-t-0 font-light px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      {{ userPlan.currency }} {{ investment.amount.toFixed(2) }}
                      </td>
                      <td
                        class="border-t-0 px-2 font-light align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        {{ investment.status }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" 
                        @click="{}"
                      >
                      <i class="pi pi-send text-xs" style="font-size: 10px;"></i> Pay 
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div v-if="showDebitModal" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Debit Loan
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="showDebitModal=false"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <div class="w-full px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Debit Amount
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.debit_amount.$model"
                  :disabled="loaders().repayLoan"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.debit_amount.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="showDebitModal=false"
            >
              Close
            </button>
            <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 flex rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button" 
            :disabled="loaders().repayLoan"
            @click="processDebitLoan">
              Debit
              <span v-if="loaders().repayLoan" class="flex loader loader-sm"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDebitModal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
// import { Loader } from 'google-maps';
import { strings } from '@/mixins'
import { confirmAction } from '@/utils'
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedPlan" ],
  components: {
  },
  mixins: [strings],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedPlan: function(plan) {
      this.userPlan = plan
    }
  },
  data() {
    return {
      userPlan: this.selectedPlan || {},
      loan: {},
      showDebitModal: false,
      debit_amount: 0,
      showInstallments: true
    }
  },
  validations() {
    return {
      userPlan: {
        amount: { required, decimal },
        base_currency: { required },
        rate: { required, decimal },
        status: { required },
      },
      debit_amount: { required, decimal }
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
    ...mapActions([ 
      "repayLoan", 
      "updatePlanUser",
      "terminatePlan",
      "updateSavings" 
    ]),
    updateExistingPlan() {
      const data = this.userPlan;
      this.updatePlanUser(data)
    },
    nudgeSavings(saving) {
      console.log(saving)
      toaster.info("sent notification")
    },
    handleUpdateSavings(saving) {
      confirmAction({
        confirmText: "Confirm cancellation of this savings installment",
        successText: "The savings installment has been cancelled",
        cancelText: "This action was aborted"
      }, () => {
        const data = {
          ...saving,
          status: "CANCELLED"
        }
        this.updateSavings(data).then( () => {
          toaster.success("Successfully cancelled savings installment.")
        })
      });
    },
    debitLoan(loan) {
      this.loan = loan,
      this.debit_amount = loan.amount - loan.amount_paid;
      this.showDebitModal = true
      toaster.info("initiated")
    },
    processDebitLoan() {
      const self = this
      confirmAction({
        confirmText: "Are you sure you want to debit this plan?",
        successText: "The plan has been debitted",
        cancelText: "Debit of plan aborted"
      }, () => {
        this.repayLoan({id: this.loan.id, amount: this.debit_amount})
          .then( () => {
            self.loan.amount_paid = self.debit_amount;
            self.showDebitModal=false
          })
      });
    },
    terminate() {
      confirmAction({
        confirmText: "Are you sure you want to terminate this plan?",
        successText: "The plan has been terminated",
        cancelText: "Termination of plan aborted"
      }, () => {
        this.terminatePlan(this.userPlan).then( () => {
          this.userPlan.status = "TERMINATED"
        })
      });
    },
  }
}
</script>