<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="relative px-4">
          <h3
            class="text-lg text-blueGray-700"
          >
          <i class="pi mx-2 text-sm pi-microchip"></i> Provider Logs
          </h3>
        </div>

        <div class="flex mb-3">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            :disabled="loading"
            @click="toggleModal('filter', true)"
          >
          <i class="pi pi-filter text-xs" style="font-size: 10px;"></i> Filter
          </button>
          
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="() => { get_csv=true; fetchNewProviderLogPage()}"
            :disabled="loading || loaders().getProviderLogs"
          >
          <i class="pi pi-download text-xs" style="font-size: 10px;"></i> Download
          </button>
        </div>

      </div>
    </div>

    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Provider
            </th>

            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Type
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Reference
            </th>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              IP
            </th>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th>
          </tr>
        </thead>
        <tbody v-if="has('activities.provider-logs.view')">
          <template v-if="!loaders().getProviderLogs && providerLogs().data">
            <tr 
              v-for="(payLog, i) in providerLogs().data" 
              :key="i"
              @click.prevent="providerLog = {
                reference: payLog.reference,
                status: payLog.status,
                ...(JSON.parse(payLog.meta) || {})
              }; toggleModal('providerLog', true)"
              class="cursor-pointer hover:bg-blueGray-100"
            >
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 capitalize text-xs whitespace-nowrap p-4 text-left"
              >
                <span class="font-light">
                  {{ payLog.name }} 
                </span>
              </th>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 capitalize text-left"
              >
                <span class="font-light">
                  {{ payLog.type?.split('_').join(" ") }} <br/>
                </span>
              </th>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                @click.prevent="clickToCopy(payLog.reference)"
              >
                <span class="font-light">
                  {{ payLog.reference }} <i v-if="payLog.reference" class="pi pi-clone text-xs" ></i>
                </span>
              </th>

              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                :class="[
                  payLog.status === 'SUCCESSFUL' ? 'text-emerald-600' : '',
                  payLog.status === 'FAILED' ? 'text-orange-500' : '',
                ]"
              >
                <!-- <i class="fas fa-circle text-orange-500 mr-2"></i> --> {{ payLog.status }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ payLog.ip }}</span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ moment(payLog.created_at).format("DD MMM YY hh:mm") }}</span>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="7" rowspan="7">
                <div class="flex items-center justify-center w-full my-6">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <permission-err v-if="!has('activities.provider-logs.view')"></permission-err>
    <empty-list v-if="(!providerLogs().data || providerLogs().data.length === 0) && !loaders().getProviderLogs && has('activities.provider-logs.view')"></empty-list>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination 
          :current_page="providerLogs().current_page" 
          :last_page="providerLogs().last_page" 
          :size="pageSize" 
          @paginate="pageChange" 
        ></circular-pagination>
      </div>
    </div>

    <div v-if="modals.providerLog" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Provider Log
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('providerLog', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <recursive-table
            :data="providerLog"
            :excludeAll="['authorization_code', 'recipient_code', 'Landmarks']"
            :exclude="['data.recipient.id', 'data.id']"
            :imagesAll="['logo', 'logo_path', 'image', 'photo', 'base64Image']"
            :telsAll="['phone']"
            :emailsAll="['email', 'support_email']"
            :datesAll="['created_at', 'updated_at', 'createdAt', 'updatedAt']"
            imageWidth="60"
            :copies="['reference', 'account_number']"
          />
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <!-- <button 
              class="text-emerald-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              @click="() => reverse(providerLog)"
              :disabled="loaders().reverse"
            >
              Reverse
            </button> -->

            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('providerLog', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.providerLog" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

    <filter-modal 
      :selects="{
        name: providerList,
        type: logList, 
      }"
      :dates="{
        from: 'Start Date',
        to: 'End Date'
      }"
      :searchable="true"
      :sizable="true"
      :visible="modals.filter"
      @close="modals.filter=false" 
      title="provider logs"
      @filter="filter"
      :loading="loading || loaders().getProviderLogs"
    ></filter-modal>
  </div>
</template>
<script>
// import TransDropdown from "@/components/Dropdowns/TransDropdown.vue";
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import { providerList, logList } from '../../utils/constant.util'
import bootstrap from "@/assets/img/bootstrap.jpg";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import RecursiveTable from "@/components/Tables/RecursiveTable.vue";
import moment from "moment"
const toaster = createToaster({ /* options */ });
import FilterModal from "@/components/Modals/FilterModal.vue";
import { clickToCopy } from "@/utils";
import PermissionErr from "@/components/Cards/PermissionErr.vue";
import EmptyList from "@/components/Cards/EmptyList.vue";

export default {
  data() {
    return {
      bootstrap,
      pageSize: 10,
      params: {},
      loading: false,
      get_csv: false,
      pagination: {},
      providerList,
      logList,
      modals: {},
      providerLog: {},
    };
  },
  components: {
    // TransDropdown,
    FilterModal,
    CircularPagination,
    RecursiveTable,
    PermissionErr,
    EmptyList
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    ...mapActions(["getProviderLogs"]),
    ...mapGetters(["providerLogs", "loaders"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewProviderLogPage();
    },
    filter(params) {
      this.params = params;
      this.fetchNewProviderLogPage(params);
    },
    fetchNewProviderLogPage(p={}) {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        ...this.params,
        ...p
      }
      const self = this
      this.loading = true

      this.getProviderLogs(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','providerLogs.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    }
  },
  mounted() {
    const params = {}
    const {search} = this.$route.query;
    if ( search ) {
      params.search = search
    }
    this.getProviderLogs(params)
  },
  created() {
    this.moment = moment
    this.clickToCopy = clickToCopy
  }
};
</script>
