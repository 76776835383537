<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-2 max-w-full flex-grow flex-1">
          <h3 class="text-base text-blueGray-700 capitalize">
            <i class="pi pi-barcode"></i> {{ type }} Gateway
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            ₦ Naira
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Logo
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left"
            >
              Provider
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Status
            </th>
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Electronic Fee
            </th>
            <!-- <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Rate
            </th> -->
            <th
              class="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap text-left min-w-140-px"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody v-if="has('gateway-config.show')">
          <template v-if="!loaders().fetchGatewayConfigs">
            <tr v-for="([k, obj], i) in Object.entries(gateways)" :key="i" class="cursor-pointer">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <img :src="obj.logo" width="25" />
              </th>
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs capitalize whitespace-nowrap p-4 text-left"
              >
                {{ obj.name }}
              </th>
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <label class="inline-flex items-center cursor-pointer">
                  <input 
                    id="opt-out" 
                    type="checkbox" 
                    :value="k"
                    v-model="activatedGateway"
                    :checked="activatedGatewayConfig.includes(k)"
                    :name="`${type}-active-gateway`"
                    class="form-checkbox border text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 rounded"
                    @change="handleCheckedEvent($event)"
                    :disabled="!has('gateway.provider.activate')"
                  >
                  <span class="ml-2 text-xs mr-2 text-emerald-600" v-if="type==='payments'"> 
                    {{ 
                      activatedGatewayConfig.includes(k) ? 
                      activatedGatewayConfig[0] === k ? 'Inbound' : 'Outbound' : 
                      'In-active' 
                    }} 
                  </span>
                  <span class="ml-2 text-xs mr-2 text-emerald-600" v-else> 
                    {{ activatedGatewayConfig.includes(k) ? 'Active' : 'In-active' }} 
                  </span>
                </label>
              </td>

              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ formatter("NGN").format(0) }}
              </td>
             
              <td
                class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 overflow-hidden"
              >
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    @click="selectGateway(obj)"
                    v-if="has('gateway-config.store') || has('gateway-config.update')"
                  >
                  <i class="pi pi-check-circle"></i> setup
                  </button>
                  <app-button
                    :classes="`${loaders().deleteGatewayConfig ? 'bg-blueGray-100 active:bg-blueGray-300 text-blueGray-700' : 'bg-red-500 text-white active:bg-red-600'} text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`"
                    :disabled="loaders().deleteGatewayConfig"
                    :loading="loaders().deleteGatewayConfig"
                    @click="handleRemoveCredential(k)"
                    title="remove"
                    v-if="has('gateway-config.destroy')"
                  >
                  <i class="pi pi-times-circle"></i> 
                  </app-button>
                  <button
                    class="bg-indigo-500 text-white active:bg-indigo-600 text-xs px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    @click="handleSeeMore(k)"
                  >
                    <i class="pi pi-eye"></i> see more
                  </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="8" rowspan="8">
                <div class="flex items-center justify-center w-full my-12">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <permission-err title="gateways" v-if="!has('gateway-config.show')"></permission-err>
    </div>
    
    <transition name="bounce" :duration="{ enter: 500, leave: 800 }">
      <div v-if="modals.gateway" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
        <div class="relative w-auto my-6 mx-auto">
          <!--content-->
          <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-lg capitalize flex">
                <img :src="gateway.logo" width="25" /> {{  gateway?.name }} information
              </h3>
              <button 
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
                v-on:click="toggleModal('gateway', false)"
              >
                <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <!--body-->
            <recursive-table 
              :data="gatewayData"
            />
            <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">            
              <button 
                class="text-red-500 background-transparent px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
                v-on:click="toggleModal('gateway', false)"
              >
                close
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="modals.gateway" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatter } from "../../utils/formatter.util";
import moment from "moment"
import { clickToCopy, confirmAction } from "@/utils";
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
import AppButton from '@/components/Form/AppButton.vue';
import Swal from 'sweetalert2'
import PermissionErr from "@/components/Cards/PermissionErr.vue";

export default {
  components: {
    RecursiveTable,
    AppButton,
    PermissionErr
  },
  props: ["gateways", "type"],
  created() {
    this.moment = moment
    this.formatter = formatter
    this.clickToCopy = clickToCopy
  },
  computed: {
    activatedGatewayConfig() {
      return this.getActivatedGateway();
    }
  },
  data() {
    return {
      modals: {},
      gateway: {},
      gatewayData: {},
      activatedGateway: []
    }
  },
  methods: {
    ...mapActions(["deleteGatewayConfig", "activateGatewayConfig", "deactivateGatewayConfig"]),
    ...mapGetters(["loaders", "gatewayConfigs", "activeGatewayConfigs"]),
    getActivatedGateway() {
      const activatedGateway = (((this.activeGatewayConfigs()||{})[this.type]||{})["activated"] || "").split(",")
      this.activatedGateway = activatedGateway;
      return activatedGateway;
    },
    selectGateway(gateway) {
      this.$emit('selectedGateway', gateway)
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    handleSeeMore(provider) {
      console.log(this.gateways[provider], this.gatewayConfigs())
      this.gateway = this.gateways[provider];
      this.gatewayData = this.gatewayConfigs()?.filter(c => c.provider === provider).map( c => ({[c.key]: c.value}))
      this.toggleModal("gateway", true)
    },
    async handleRemoveGateway(userPlan) {
      if (!confirm("Are you sure you want to remove this payment gateway?")){
        return
      }
      await this.deleteGatewayRecord({userPlan: userPlan})
    },
    handleCheckedEvent(e) {
      const _provider = e.target.value
      const provider = _provider.replace(/[^a-z0-9]/gi, '').toLowerCase();
      const isChecked = e.target.checked
      const prevActivatedGateway = this.activatedGatewayConfig || []

      const errorKeys = isChecked ? this.validateGatewayCred(provider) : []

      if ( errorKeys.length > 0 ) {
        Swal.fire({
          title: "Invalid Credentials",
          text: `Please set up ${provider} ${errorKeys.join(", ")} correctly to continue`,
          icon: "warning",
          cancelButtonColor: "#d33",
        });
        this.activatedGateway = prevActivatedGateway
        return;
      }

      confirmAction({
        confirmText: `${provider} ${this.type} gateway`,
        confirmTextHelper: `Kindly confirm you want to ${ isChecked ? 'activate': 'de-activate'} ${provider} as your ${this.type} gateway`,
        successText: `You have successfully ${ isChecked ? 'activated': 'de-activated'} ${provider} as your ${this.type} gateway`,
        cancelText: `${provider} ${ isChecked ? 'activation': 'de-activation'} aborted`
      }, () => {
        const q = isChecked ? this.activateGatewayConfig : this.deactivateGatewayConfig
        q({gateway: this.type, provider})
        .catch( () => {
          this.activatedGateway = prevActivatedGateway
        })
      }, () => {
        this.activatedGateway = prevActivatedGateway
      });
    },
    handleRemoveCredential(provider) {
      const errorKeys = this.validateGatewayCred(provider)

      if ( errorKeys.length > 0 ) {
        Swal.fire({
          title: "No credentials set up yet!",
          text: `You cannot perform this operation since ${provider} ${errorKeys.join(", ")} is not set yet.`,
          icon: "warning",
          cancelButtonColor: "#d33",
        });
        return;
      }

      confirmAction({
        confirmText: `Remove ${provider} ${this.type} credentials`,
        confirmTextHelper: `Kindly confirm you want to remove ${provider} ${this.type} credentials`,
        successText: `You have successfully removed ${provider} ${this.type} credentials`,
        cancelText: `${provider} ${this.type} credentials removal was aborted`
      }, () => {
        this.deleteGatewayConfig({gateway: this.type, provider})
        .catch( () => {})
      });
    },
    validateGatewayCred(provider) {
      const { cred } = this.gateways[provider]
      // console.log(this.gateways, cred, provider, this.gatewayConfigs())
      const errorKeys = []
      for (const key in cred ) {
        const credential = cred[key]
        if ( credential.required ) {
          const available = this.gatewayConfigs().find( c =>  c.provider.replace(/[^a-z0-9]/gi, '').toLowerCase() === provider && c.key === key && c.value?.length >= credential.minLength )
          if ( !available ) {
            errorKeys.push(key)
          }
        }
      }

      return errorKeys;
    }
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>