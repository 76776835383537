<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-4 py-3">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs font-bold capitalize">{{ selectedVas && snakeToTitleCase(selectedVas) }}</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6">
          <i class="pi pi-bold"></i> New Transaction
        </h6>
        <div class="flex flex-wrap">
          <div class="relative w-full mb-3 flex capitalize">
            {{ selectedVas && snakeToTitleCase(selectedVas) }}
          </div>
        </div>

        <hr class="my-2 border-b-1 border-blueGray-300" />

        <div v-if="['sme-airtime-topup', 'sme-data-topup'].includes(selectedVas)">
          <div class="form-group">
            <label class="form-label text-xs" for="network">Select Network</label>
            <ul class="box-service mt-3">
              <li v-for="(ips, i) in ['mtn', 'airtel', 'glo', 'etisalat']" :key="i">
                <label class="stretched-link">
                  <div class="icon-box bg_color_1 bg-orange-200" :class="{
                    'bg-orange-500': mobile.network === ips
                  }">
                    <img :src="logos[ips]" style="width: 26px; border-radius: 50%;" />
                  </div>
                  <input 
                    type="radio" 
                    name="network" 
                    :value="ips" 
                    id="network" 
                    :disabled="loaders().vendAirtime || loaders().vendData || loaders().fetchData"
                    v-model="v$.mobile.network.$model"
                    style="display: none;"
                    @change="(e) => fetchPackage(e.target.value)"
                  />
                  <span class="text-xs">{{ ips }}</span>
                </label>
              </li>
            </ul>
          </div>
          <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.mobile.network.$errors" :key="index">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div class="flex flex-wrap" v-if="selectedVas === 'sme-data-topup'">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Package
              </label>
              <select
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.mobile.package.$model"
                :disabled="loaders().fetchData"
                @change="handlePackageChange"
              >
                <option v-for="(data, i) of vasdata()" :key="i" :value="data.code">{{ data.description }} {{ formatter("NGN").format(data.amount) }}</option>
              </select>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.mobile.package.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="selectedVas === 'sme-cable-tv'">
          <div class="form-group">
            <label class="form-label text-xs" for="cable-network">Select Service</label>
            <ul class="box-service mt-3">
              <li v-for="(ips, i) in ['DSTV', 'GOTV', 'Startimes']" :key="i">
                <label class="stretched-link">
                  <div class="icon-box bg_color_1 bg-orange-200" :class="{
                    ' bg-orange-500': cable.service === ips
                  }">
                    <img :src="logos[ips]" style="width: 26px; border-radius: 50%;" />
                  </div>
                  <input 
                    type="radio" 
                    name="cable-network" 
                    :value="ips" 
                    id="cable-network" 
                    :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable"
                    v-model="v$.cable.service.$model"
                    style="display: none;"
                    @change="changeCable"
                  />
                  <span class="text-xs">{{ ips }}</span>
                </label>
              </li>
            </ul>

            <!-- <select class="form-select" :disabled="loaders().fetchCable || loaders().vendCable || loaders().validateCable" id="cable-network" name="cable-network" v-model="v$.cable.service.$model" aria-label="cable-network" @change="changeCable">
              <option v-for="(ips, i) in ['DSTV', 'GOTV', 'Startimes']" :key="i" :value="ips">{{ ips }}</option>
            </select> -->
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.service.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full px-1">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  Bouquet
                </label>
                <select
                  class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.cable.code.$model"
                  :disabled="loaders().fetchCable || loaders().validateCable || loaders().vendCable"
                  @change="handlePackageChange"
                >
                  <option v-for="(data, i) of vascable()" :key="i" :value="data.code">{{ data.description }} {{ formatter("NGN").format(data.amount) }}</option>
                </select>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.code.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full px-1">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  IUC Number
                </label>
                <input
                  type="text"
                  class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.cable.iuc.$model"
                  :disabled="loaders().vendCable || loaders().validateCable || loaders().fetchCable"
                  @keyup="validateCableData"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.iuc.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="selectedVas === 'sme-power'">
          <div class="flex flex-wrap">
            <div class="w-full px-1">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  Biller
                </label>
                <select
                  class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.power.service.$model"
                  :disabled="loaders().vendPower || loaders().validatePower || loaders().fetchPower"
                >
                  <option v-for="(data, i) of vaspower()" :key="i" :value="data.code">{{ data.description }} {{ formatter("NGN").format(data.amount) }}</option>
                </select>
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.cable.service.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full px-1">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  Meter Number
                </label>
                <input
                  type="text"
                  class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.power.meter.$model"
                  :disabled="loaders().vendPower || loaders().validatePower || loaders().fetchPower"
                  @keyup="validatePowerData"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.power.meter.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="flex flex-wrap" v-if="['sme-power', 'sme-cable-tv'].includes(selectedVas)">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Validation
              </label>
              <input
                type="text"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.verification.$model"
                :disabled="true"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.verification.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Amount
              </label>
              <input
                type="number"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.amount.$model"
                :disabled="['sme-data-topup', 'sme-cable-tv'].includes(selectedVas)"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.amount.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-full px-1">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Phone Number
              </label>
              <input
                type="text"
                class="border-0 px-2 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-xs shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.mobile.phone.$model"
                :disabled="loaders().fetchData || loaders().fetchCable || loaders().fetchPower"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.mobile.phone.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mb-3 px-1 mt-3">
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            @click="(e) => $emit('close', e)"
          >
            <i class="pi pi-ban text-xs"></i> Cancel
          </button>
          <app-button
            :classes="`text-xs px-3 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 ${!canSubmit ? 'bg-blueGray-100 active:bg-blueGray-300 text-blueGray-700' : 'bg-emerald-500 active:bg-emerald-600 text-white'}`"
            type="button"
            :disabled="!canSubmit"
            :loading="loaders().vendAirtime || loaders().vendCable || loaders().vendData || loaders().vendPower"
            @click="handleBuyVasClick"
            title="Submit"
          >
          <i class="pi pi-refresh text-xs"></i>
          </app-button>
        </div>
      </form>
    </div>
    <transaction-pin-modal 
      currency="NGN"
      @close="modals.transactionPin=false"
      :visible="modals.transactionPin"
      @verified="authorizationCallback"
    ></transaction-pin-modal>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import AppButton from '@/components/Form/AppButton.vue';
import { strings } from '@/mixins'
import { snakeToTitleCase, formatter, confirmAction } from '@/utils';
import { createToaster } from "@meforma/vue-toaster";
import mtnLogo from "@/assets/img/services/mtn.png";
import airtelLogo from "@/assets/img/services/airtel.png";
import gloLogo from "@/assets/img/services/glo.png";
import etisalatLogo from "@/assets/img/services/etisalat.png";
import dstvLogo from "@/assets/img/services/dstv.png";
import gotvLogo from "@/assets/img/services/gotv.png";
import startimesLogo from "@/assets/img/services/startimes.png";
import TransactionPinModal from "@/components/Modals/TransactionPinModal.vue";
import Swal from 'sweetalert2'
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedVas" ],
  components: {
    AppButton,
    TransactionPinModal
  },
  mixins: [strings],
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedVas: function(vas) {
      console.log(vas)
    }
  },
  computed: {
    canSubmit() {
      return (
        !this.loaders().vendAirtime && 
        !this.loaders().vendCable && 
        !this.loaders().vendData && 
        !this.loaders().vendPower &&
        (
          (this.amount && !['sme-cable-tv'].includes(this.validateCable)) ||
          (this.mobile.phone && this.mobile.network && ['sme-airtime-topup', 'sme-data-topup'].includes(this.validateCable)) || 
          (this.cable.iuc && this.cable.code && ['sme-cable-tv'].includes(this.validateCable)) ||
          (this.verification && ['sme-power', 'sme-cable-tv'].includes(this.validateCable)) ||
          (this.power.service && this.power.meter && ['sme-power'].includes(this.validateCable))
        )
      )
    }
  },
  data() {
    return {
      amount: "",
      verification: "",
      vasMethod: null,
      modals: {},
      mobile: {},
      cable: {},
      power: {},
      powerData: {},
      logos: {
        mtn: mtnLogo,
        airtel: airtelLogo,
        glo: gloLogo,
        etisalat: etisalatLogo,
        DSTV: dstvLogo,
        GOTV: gotvLogo,
        Startimes: startimesLogo,
      }
    }
  },
  mounted() {
    this.fetchPackage()
  },
  created() {
    this.snakeToTitleCase = snakeToTitleCase
    this.formatter = formatter
  },
  validations() {
    return {
      verification: {},
      amount: {min: minLength(2)},
      mobile: {
        network: { required },
        phone: { required, min: minLength(11)},
        package: { required },
        confirm: { required }
      },
      cable: {
        service: { required },
        iuc: { required, min: minLength(10)},
        code: {},
        phone: {},
        token: {},
        confirm: { required }
      },
      power: {
        service: { required },
        meter: { required, min: minLength(10)},
        phone: {},
        token: {},
        confirm: { required }
      }
    }
  },
  methods: {
    ...mapGetters([
      "loaders",
      "vaspower",
      "vasdata",
      "vascable",
      "auth",
      "account",
    ]),
    ...mapActions([
      "vendAirtime",
      "fetchData",
      "vendData",
      "fetchPower",
      "validatePower",
      "vendPower",
      "fetchCable",
      "validateCable",
      "vendCable"
    ]),
    buyAirtime: function() {
      if ( this.account().wallet.NGN.ledger_balance < this.amount ) {
        toaster.show("you cannot top up above your available balance of " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }
      if (this.mobile.confirm) delete this.mobile.confirm
      const data = {
        ...this.mobile, 
        amount: this.amount,
        is_sme: true
      }
      this.vendAirtime(data).then(() => {
        Swal.fire({
          title: "Successful",
          text: "That transaction was successful",
          icon: "mark"
        });
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    buyData: function() {
      if (this.mobile.confirm) delete this.mobile.confirm
      const data = {
        ...this.mobile,
        is_sme: true
      }
      this.vendData(data).then(() => {
        Swal.fire({
          title: "Successful",
          text: "That transaction was successful",
          icon: "mark"
        });
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      });
    },
    validateCableData: function() {
      if ( !this.cable.iuc || this.cable.iuc.length < 10 ) {
        return;
      }
      const data = {
        service: this.cable.service,
        iuc: this.cable.iuc,
        code: this.cable.code,
        amount: this.cable.amount,
        is_sme: true
      }
      const self = this;
      this.validateCable(data).then(res => {
        const { code, description } = res
        self.cable.token = code;
        self.verification = description
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    validatePowerData: function() {
      if ( !this.power.meter || this.power.meter.length < 10 ) {
        return;
      }
      const data = {
        service: this.power.service.code,
        meter: this.power.meter,
        amount: this.power.amount,
        is_sme: true
      }
      const self = this;
      this.validatePower(data).then( res => {
        self.power.token = res.code;
        if ( res.description.minimumAmount ) {
          self.power.min = res.description.minimumAmount
        }
        self.verification = res.description
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    buyCable: function() {

      if ( this.account().wallet.NGN.ledger_balance < this.cable.amount ) {
        toaster.show("you cannot subscribe above your available balance of " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }

      const code = this.cable.code;
      const amount = this.cable.amount
      delete this.cable.confirm
      const data = {
        ...this.cable,
        code,
        amount,
        is_sme: true
      }
      this.vendCable(data).then(() => {
        Swal.fire({
          title: "Successful",
          text: "That transaction was successful",
          icon: "mark"
        });
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      });
    },
    buyPower: function() {

      if ( this.account().wallet.NGN.ledger_balance < this.amount ) {
        toaster.show("you cannot buy power above your available balance of " + this.account().wallet.NGN.base_currency + " " + this.account().wallet.NGN.ledger_balance.toFixed(3) );
        return
      }

      const service = this.power.service.code;
      const amount = this.power.service.min
      if ( amount && this.amount < amount ) {
        toaster.error("please specify an amount greater than " + amount );
      }
      delete this.power.confirm
      const data = {
        ...this.power,
        service
      }, self = this;
      this.vendPower(data).then( res => {
        self.powerData = res;
        Swal.fire({
          title: "Successful",
          text:  `That transaction was successful. ${res}`,
          icon: "mark"
        });
      }).catch(err => {
        const { message = "Something went wrong"} = err
        toaster.warning(message)
      })
    },
    changeCable(e) {
      this.fetchPackage(e.target.value)
    },
    fetchPackage(value) {
      let network = value || "mtn"
      let bouquet = value || "DSTV"

      switch (this.selectedVas) {
        case "sme-data-topup":
          this.mobile.network = network
          this.fetchData({network, params: {is_sme: true}})
          break;
        case "sme-power":
          this.fetchPower({is_sme: true});
          break;
        case "sme-cable-tv":
          this.cable.service = bouquet
          this.fetchCable({bouquet, params: {is_sme: true}})
          break;
        default:
          break;
      }
    },
    handlePackageChange(e) {
      const code = e.target.value
      let p = {}
      switch (this.selectedVas) {
        case "sme-data-topup":
          p = this.vasdata().find( d => +d.code === +code)
          this.mobile.amount = p.amount
          this.amount = p.amount
          break;
        case "sme-power":
          this.validatePowerData()
          break;
        case "sme-cable-tv":
          p = this.vascable().find( d => d.code === code)
          this.cable.amount = p.amount
          this.amount = p.amount
          this.validateCableData()
          break;
        default:
          break;
      }
    },
    handleBuyVasClick() {
      confirmAction({
          confirmText: `Kindly confirm this ${this.selectedVas} transaction.`,
          successText: `This ${this.selectedVas} transaction was successfully submitted`,
          cancelText: `This ${this.selectedVas} transaction has been cancelled`
        }, () => {
          if ( this.account().wallet.NGN.ledger_balance < this.amount ) {
            toaster.show(`you cannot top up above your available balance of ${this.account().wallet.NGN.base_currency} ${this.account().wallet.NGN.ledger_balance.toFixed(3)}`);
            return
          }
          switch (this.selectedVas) {
            case "sme-airtime-topup":
              this.vasMethod = "buyAirtime"
              // this.buyAirtime()
              break;
            case "sme-data-topup":
              // this.buyData()
              this.vasMethod = "buyData"
              break;
            case "sme-power":
              // this.buyPower()
              this.vasMethod = "buyPower"
              break;
            case "sme-cable-tv":
              // this.buyCable()
              this.vasMethod = "buyCable"
              break;
            default:
              toaster.error("Try to select a value added service to purchase")
              throw new Error("Invalid vas");
          }
          this.modals.transactionPin = true
      }, null, false);
    },
    authorizationCallback(verified) {
      if (!verified) {
        return;
      }
      this.modals.transactionPin = false
      if (this.is('restricted')) {
        toaster.info("Cannot perform this transaction!");
        return;
      }
      this[this.vasMethod]();
    },
  }
}
</script>

<style scoped>
.box-service {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px 12px;
    padding: 0px
  }
  
  .box-service li a, .box-service li .stretched-link {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      text-align: center;
      text-decoration: none !important;
      color: var(--primary-color);
  }
  
  .box-service li .icon-box {
    width: 44px;
    height: 44px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(var(--bs-success-rgb), 0.2) !important;
    color: var(--primary-color) !important; */
  }

  [data-theme=dark] body .box-service li a, [data-theme=dark] body .box-service li .stretched-link {
    color: #ffffff;
  }
</style>
