<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  async mounted() {
    await this.fetchTenant()
    await this.getSubscription()
  },
  methods: {
    ...mapActions(["fetchTenant", "getSubscription"]),
  }
}
</script>
