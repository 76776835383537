<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold"><i class="pi pi-file-check"></i> Compliance</h6>
        <div class="flex mb-3">
          <button
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button"
            @click="page=pages.BUSINESS"
            :class="{
              'bg-indigo-500': page===pages.BUSINESS,
              'active:bg-indigo-600': page===pages.BUSINESS,
              'bg-emerald-500': page!==pages.BUSINESS,
              'active:bg-emerald-600': page!==pages.BUSINESS
            }"
          >
          <i class="pi pi-briefcase text-xs" style="font-size: 10px;"></i> Business
          </button>
          
          <button
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="page=pages.VERIFICATION"
            :class="{
              'bg-indigo-500': page===pages.VERIFICATION,
              'active:bg-indigo-600': page===pages.VERIFICATION,
              'bg-emerald-500': page!==pages.VERIFICATION,
              'active:bg-emerald-600': page!==pages.VERIFICATION
            }"
          >
          <i class="pi pi-verified text-xs" style="font-size: 10px;"></i> BVN
          </button>

          <button
            class="text-white text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="page=pages.NEXT_OF_KIN"
            :class="{
              'bg-indigo-500': page===pages.NEXT_OF_KIN,
              'active:bg-indigo-600': page===pages.NEXT_OF_KIN,
              'bg-emerald-500': page!==pages.NEXT_OF_KIN,
              'active:bg-emerald-600': page!==pages.NEXT_OF_KIN
            }"
          >
          <i class="pi pi-users text-xs" style="font-size: 10px;"></i> Next of Kin
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-4 py-10 pt-0">
      <form>
        <div v-if="page===pages.BUSINESS">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 px-2">
            <i class="pi pi-briefcase"></i> Business Details
          </h6>
          <div class="w-full lg:w-12/12 px-2" v-if="( businesses().data && businesses().data.length === 0) || Object.values(business).length > 0">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Business Name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.name.$model"
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Business Address
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.address.$model"
              />
            </div>

            <div class="flex flex-wrap">
              <div class="w-full lg:w-6/12 pr-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                    v-model="v$.business.city.$model"
                  />
                </div>
              </div>
              <div class="w-full lg:w-6/12 pl-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block text-blueGray-600 text-xs mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                    v-model="v$.business.country.$model"
                  />
                </div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block  text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Support Email
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.business.support_email.$model"
              />
            </div>

            <div class="relative w-full mb-3 mt-3">
              <button
                class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button" 
                :disabled="loaders().addBusiness || loaders().updateBusiness || v$.business.$invalid"
                @click="saveBusiness"
              >
                <span v-if="loaders().addBusiness || loaders().updateBusiness" class="flex loader loader-sm"></span>
                <span v-else><i class="pi pi-save"></i> Save Business</span>
              </button>
            </div>
          </div>
          <div v-else class="px-4 text-emerald-500">
            Completed <i class="pi pi-file-check"></i>
          </div>
        </div>

        <div v-if="page===pages.VERIFICATION">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 px-2">
            <i class="pi pi-verified"></i> BVN Verification
          </h6>
          <div class="flex flex-wrap" v-if="!auth().meta?.is_verified">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block text-blueGray-600 text-xs mb-2"
                  htmlFor="grid-password"
                >
                  BVN
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.verification.bvn.$model"  
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.verification.bvn.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>

              <div class="relative w-full mb-3">
                <button
                  class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                  type="button" 
                  :disabled="loaders().verifyAccount || v$.verification.bvn.$invalid"
                  @click="verifyByBVN"
                >
                  <span v-if="loaders().verifyAccount" class="flex loader loader-sm"></span><span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
          <div v-else class="px-4 text-emerald-500">
            Verified <i class="pi pi-file-check"></i>
          </div>
        </div>

        <div v-if="page===pages.NEXT_OF_KIN">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 px-2">
            <i class="pi pi-users"></i> Next of Kin <small>({{ nextOfKins().data.length }} of 3 supplied)</small>
          </h6>
          <div class="w-full lg:w-12/12 px-2" v-if="( nextOfKins().data && nextOfKins().data.length < 3 )|| Object.values(nextOfKin).length > 0">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Name
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.nextOfKin.name.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.name.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Phone
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.nextOfKin.phone.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.phone.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.nextOfKin.email.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.email.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
                htmlFor="grid-password"
              >
                Relationship
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                v-model="v$.nextOfKin.relationship.$model"
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.relationship.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3 mt-3">
              <button
                class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button" 
                :disabled="loaders().saveNextOfKin || loaders().updateNextOfKin || v$.nextOfKin.$invalid"
                @click="addNextOfKin"
              >
                <span v-if="loaders().saveNextOfKin || loaders().updateNextOfKin" class="flex loader loader-sm"></span>
                <span v-else><i class="pi pi-save"></i> Save</span>
              </button>
            </div>
          </div>
          <div v-else class="px-4 text-emerald-500">
            3 next of kins supplied <i class="pi pi-file-check"></i>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core';
import { 
  required, 
  minLength,
  numeric,
  email
  // decimal, numeric, maxLength 
} from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: ["activeBusiness", "activeNextOfKin"],
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      business: {
        name         : {required}, 
        support_email: {required}, 
        logo         : {}, 
        address      : {required}, 
        phone        : {numeric, min: minLength(11)},
        city         : {},
        postal_code  : {},
        country      : {}, 
        about        : {},
        webhook_url  : {},
        document     : {} 
      },
      nextOfKin: {
        name  : {required}, 
        email : {required, email}, 
        phone : {numeric, min: minLength(11)}, 
        relationship : {required}, 
      },
      verification: {
        bvn : {required, numeric, min: minLength(11)}
      }
    }
  },
  watch: {
    activeBusiness: function(business) {
      this.business = business
    },
    activeNextOfKin: function(nextOfKin) {
      this.nextOfKin = nextOfKin
    }
  },
  data() {
    return {
      business: {},
      nextOfKin: {},
      verification: {},
      errors: {},
      pages: {
        BUSINESS: "BUSINESS",
        VERIFICATION: "VERIFICATION",
        NEXT_OF_KIN: "NEXT_OF_KIN"
      },
      page: "BUSINESS"
    }
  },
  methods: {
    ...mapActions([
      "addBusiness", 
      "updateBusiness",
      "saveNextOfKin",
      "updateNextOfKin",
      "deleteNextOfKin",
      "verifyViaBVN"
    ]),
    ...mapGetters(["loaders", "businesses", "nextOfKins", "userProfile", "auth"]),
    saveBusiness() {
      const query = this.business.id ? this.updateBusiness(this.business) : this.addBusiness(this.business);
      query.then(() => {})
    },
    addNextOfKin() {
      const query = this.nextOfKin.id ? this.updateNextOfKin(this.nextOfKin) : this.saveNextOfKin(this.nextOfKin);
      const self = this;
      query.then(() => {
        self.nextOfKin = {}
      })
    },
    verifyByBVN() {
      const data = this.getDetails();
      if(!(Object.values(data).some( e => e))) {
        toaster.info("Please update your profile first")
        return;
      }
      data.bvn = this.verification.bvn;
      this.verifyViaBVN(data).then(() => {}).catch( e => {
        const { message } = e;
        this.errors = { message }
      })
    },
    getDetails() {
      const data = {}
      const profile = this.userProfile();
      
      if ( profile.first_name && profile.last_name) {
        data.firstname = profile.first_name;
        data.lastname = profile.last_name;
      } else {
        const names = this.user.name.split(" ");
        data.firstname = names[0];
        data.lastname = names[1] || ""
      }

      data.dob = profile.dob;
      return data;
    },
  },
};
</script>