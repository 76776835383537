import http from "./http";

export const AddGatewayConfig = async data => {
  return await http.post("/gateway-config", data);
};

export const UpdateGatewayConfig = async data => {
  return await http.put("/gateway-config", data);
};

export const GetGatewayConfigs = async () => {
  return await http.get("/gateway-config");
};

export const DeleteGatewayConfig = async (gateway, provider) => {
  return await http.delete(`/gateway-config/${gateway}/${provider}`);
};

export const ActivateGatewayConfig = async (gateway, provider) => {
  return await http.patch(`/gateway-config/${gateway}/${provider}/activate`);
};

export const DeactivateGatewayConfig = async (gateway, provider) => {
  return await http.patch(`/gateway-config/${gateway}/${provider}/deactivate`);
};