<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              <i class="pi pi-dollar"></i> Transactions
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0">
            <a class="text-xs px-5 py-3 shadow-lg rounded leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
              <i class="pi pi-receipt"></i> Payment Logs
            </a>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div class="flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div class="w-full">
                <card-transaction />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <div class="w-full">
                <card-payment-log />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full mb-12 px-4">
      <card-transaction color="dark" />
    </div> -->
  </div>
</template>
<script>
import CardTransaction from "@/components/Cards/CardTransaction.vue";
import CardPaymentLog from "@/components/Cards/CardPaymentLog.vue";

export default {
  components: {
    CardTransaction,
    CardPaymentLog,
  },
  data() {
    return {
      openTab: 1
    }
  },
  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
};
</script>
