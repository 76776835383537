export const fetchGatewayConfigs = (state, {configs, activeConfigs}) => {
    state.gatewayConfigs = configs;
    state.activeGatewayConfigs = activeConfigs;
}

export const addGatewayConfig = (state, data) => {
    const newState = state.gatewayConfigs.map(config => {
        const idx = data.findIndex( c => c.key === config.key)
        return (idx > -1) ? data[idx] : config
    });
    state.gatewayConfigs = newState;
}

export const updateGatewayConfig = (state, {result, data}) => {
    const { category = 'push' } = data;
    const idx = state.gatewayConfigs[category].data.findIndex(
        (_c) => _c.id === result.id
    );
    if (idx < 0) {
        return;
    }
    state.gatewayConfigs[category].data[idx] = result;
}


export const deleteGatewayConfig = (state, {provider, gateway}) => {
    state.activeGatewayConfigs[gateway] = {};

    const gatewayConfigs = state.gatewayConfigs.filter(
        (_gatewayConfigs) => _gatewayConfigs.provider !== provider
    );
    state.gatewayConfigs = gatewayConfigs;
}

const addActivated = (activated, provider) => {
    if (!activated || activated === "") return provider
    const activatedList = activated.split(",") || []
    return activatedList.includes(provider) ? activatedList.join(",") : [...activatedList, provider].join(",")
}

const removeActivated = (activated, provider) => {
    if (!activated || activated === "") return ""
    return activated.split(",").filter( a => a !== provider).join(",")
}

export const activateGatewayConfig = (state, {data, provider, gateway}) => {
    state.activeGatewayConfigs[gateway] = {
        ...state.activeGatewayConfigs[gateway],
        [provider]: data,
        activated: addActivated(state.activeGatewayConfigs[gateway]["activated"], provider)
    }
}

export const deactivateGatewayConfig = (state, {provider, gateway}) => {
    const gateways = state.activeGatewayConfigs[gateway]
    delete gateways[provider]
    state.activeGatewayConfigs[gateway] = gateways;
    state.activeGatewayConfigs[gateway]["activated"] = removeActivated(state.activeGatewayConfigs[gateway]["activated"], provider)
}

const gatewayConfigMutation = {
    fetchGatewayConfigs,
    addGatewayConfig,
    updateGatewayConfig,
    deleteGatewayConfig,
    activateGatewayConfig
};

export default gatewayConfigMutation;