<template>
    <div class="w-full text-blueGray-400 text-xs flex justify-center items-center flex-col p-8 mt-8">
        <span>Ops! you do not have the permission to view {{ this.title || 'this content' }}.</span>
        <br/>
        <span
          class="w-20 h-20 text-sm text-blueGray-700 bg-blueGray-200 inline-flex items-center justify-center rounded-full mt-8"
        >
            <img
                alt="..."
                class="w-full rounded-full align-middle border-none shadow-lg w-20 h-20"
                :src="image"
            />
        </span>
    </div>
</template>

<script>
import image from "@/assets/img/permission.png";

export default {
    props: ['title'],
    data() {
        return {
            image: image,
        };
    },
}
</script>