export const fetchFees = (
    state, fees
) => {
    state.fees = fees;
}

export const saveFee = (state, fee) => {
    const fees = [
        ...state.fees.data,
        fee
    ];
    state.fees.data = fees;
};

export const updateFee = (state, fee) => {
    const currIdx = state.fees.data.findIndex(
        (f) => f.id === fee.id
    );
    if (currIdx < 0) {
        return;
    }
    state.fees[currIdx] = fee;
};

export const deleteFee = (state, fee) => {
    const fees = state.fees.data.filter(
        f => f.id !== fee.id
    );
    state.fees.data = fees;
};


const FeeMutation = {
    fetchFees,
    saveFee,
    updateFee,
    deleteFee
};

export default FeeMutation;